import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';

const HowItWorks = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="bg-blue-600 text-white shadow-md">
        <div className="container mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className="text-xl font-bold">TechConnect Santiago</h1>
          
          {/* Navigation */}
          <nav className="flex items-center space-x-6">
            <button 
              onClick={() => navigate('/')} 
              className="flex items-center text-white hover:underline"
            >
              <ArrowLeft className="mr-1 h-4 w-4" />
              Volver
            </button>
          </nav>
        </div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl font-bold text-center mb-8 text-gray-800">¿Cómo Funciona TechConnect?</h1>
          
          <div className="bg-white shadow-md rounded-lg p-8 mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-blue-600">Para Clientes</h2>
            
            <div className="space-y-6">
              <div className="flex items-start">
                <div className="bg-blue-100 text-blue-800 rounded-full w-8 h-8 flex items-center justify-center font-bold mr-4 flex-shrink-0">1</div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Busca un técnico</h3>
                  <p className="text-gray-600">
                    Utiliza nuestra herramienta de búsqueda para encontrar técnicos especializados según tus necesidades. 
                    Puedes filtrar por especialidad, ubicación y disponibilidad.
                  </p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="bg-blue-100 text-blue-800 rounded-full w-8 h-8 flex items-center justify-center font-bold mr-4 flex-shrink-0">2</div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Revisa perfiles y calificaciones</h3>
                  <p className="text-gray-600">
                    Explora los perfiles de los técnicos para ver sus habilidades, experiencia, tarifas y reseñas de otros clientes.
                    Esto te ayudará a elegir el profesional adecuado para tu problema.
                  </p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="bg-blue-100 text-blue-800 rounded-full w-8 h-8 flex items-center justify-center font-bold mr-4 flex-shrink-0">3</div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Contacta al técnico</h3>
                  <p className="text-gray-600">
                    Una vez que encuentres el técnico adecuado, puedes contactarlo directamente a través de la información 
                    proporcionada en su perfil para coordinar el servicio.
                  </p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="bg-blue-100 text-blue-800 rounded-full w-8 h-8 flex items-center justify-center font-bold mr-4 flex-shrink-0">4</div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Califica el servicio</h3>
                  <p className="text-gray-600">
                    Después de recibir el servicio, puedes dejar una reseña y calificación para ayudar a otros usuarios
                    y reconocer el buen trabajo del técnico.
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="bg-white shadow-md rounded-lg p-8">
            <h2 className="text-2xl font-semibold mb-4 text-blue-600">Para Técnicos</h2>
            
            <div className="space-y-6">
              <div className="flex items-start">
                <div className="bg-blue-100 text-blue-800 rounded-full w-8 h-8 flex items-center justify-center font-bold mr-4 flex-shrink-0">1</div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Regístrate como técnico</h3>
                  <p className="text-gray-600">
                    Crea tu perfil profesional completando el formulario de registro con tus datos personales,
                    información de contacto y detalles sobre tus servicios.
                  </p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="bg-blue-100 text-blue-800 rounded-full w-8 h-8 flex items-center justify-center font-bold mr-4 flex-shrink-0">2</div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Personaliza tu perfil</h3>
                  <p className="text-gray-600">
                    Completa tu perfil con información detallada sobre tus habilidades, experiencia, especialidades, 
                    fotografías de trabajos anteriores y establece tus tarifas.
                  </p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="bg-blue-100 text-blue-800 rounded-full w-8 h-8 flex items-center justify-center font-bold mr-4 flex-shrink-0">3</div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Recibe solicitudes</h3>
                  <p className="text-gray-600">
                    Los clientes interesados en tus servicios te contactarán directamente. Puedes gestionar tus citas
                    según tu disponibilidad y preferencias.
                  </p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="bg-blue-100 text-blue-800 rounded-full w-8 h-8 flex items-center justify-center font-bold mr-4 flex-shrink-0">4</div>
                <div>
                  <h3 className="text-lg font-medium mb-2">Construye tu reputación</h3>
                  <p className="text-gray-600">
                    Las reseñas positivas de tus clientes mejorarán tu visibilidad en la plataforma y te ayudarán a 
                    atraer más trabajo. Brinda un servicio de calidad para destacarte.
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="text-center mt-12">
            <button 
              onClick={() => navigate('/register-technician')} 
              className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 px-6 rounded-md shadow-md transition duration-300 mx-2"
            >
              Regístrate como Técnico
            </button>
            
            <button 
              onClick={() => navigate('/search')} 
              className="bg-gray-800 hover:bg-gray-900 text-white font-medium py-3 px-6 rounded-md shadow-md transition duration-300 mx-2"
            >
              Buscar Técnicos
            </button>
          </div>
        </div>
      </main>
      
      {/* Footer */}
      <footer className="bg-gray-800 text-gray-300 py-8 px-4 mt-auto">
        <div className="container mx-auto">
          <div className="text-center">
            <p>&copy; {new Date().getFullYear()} TechConnect Santiago. Todos los derechos reservados.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HowItWorks; 