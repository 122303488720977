import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { technicianService } from '../services/api';
import { toast } from 'react-hot-toast';

const EditTechnicianProfile = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [formData, setFormData] = useState({
    bio: '',
    experience_years: '',
    hourly_rate: '',
    city: '',
    address: '',
    available: true
  });
  const [profileImage, setProfileImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user) {
      toast.error('Debe iniciar sesión para editar su perfil');
      navigate('/login');
      return;
    }

    console.log('User data in EditTechnicianProfile:', user);
    
    const fetchProfile = async () => {
      setLoading(true);
      setError(null);
      
      try {
        const response = await technicianService.getOwnProfile();
        console.log('Technician profile data:', response);

        if (response && response.technician) {
          setFormData({
            bio: response.technician.bio || '',
            experience_years: response.technician.experience_years || '',
            hourly_rate: response.technician.hourly_rate || '',
            city: response.technician.city || '',
            address: response.technician.address || '',
            available: response.technician.available !== false // Default to true if not specified
          });
          
          if (response.technician.profile_image) {
            setImagePreview(`/uploads/${response.technician.profile_image}`);
          }
        } else {
          setError('No se encontró información del perfil.');
        }
      } catch (error) {
        console.error('Error loading profile:', error);
        setError('Error al cargar el perfil: ' + (error.message || 'Error desconocido'));
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [navigate, user]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        toast.error('La imagen es demasiado grande. El tamaño máximo es 5MB.');
        return;
      }
      setProfileImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleRemoveImage = async () => {
    try {
      setSubmitting(true);
      await technicianService.removeProfileImage();
      setProfileImage(null);
      setImagePreview(null);
      setFormData(prev => ({ ...prev, profile_image: null }));
    } catch (error) {
      setError('Error removing profile picture. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError(null);
    
    try {
      // First update profile data - use correct function name
      await technicianService.updateTechnician(formData);
      
      // Then update image if one was selected
      if (profileImage) {
        setUploadingImage(true);
        const formDataObj = new FormData();
        formDataObj.append('profile_image', profileImage);
        
        // Debug FormData contents
        console.log('Image upload details:', {
          filename: profileImage.name,
          type: profileImage.type,
          size: profileImage.size
        });
        
        // Log all entries in FormData
        for (let pair of formDataObj.entries()) {
          console.log(`FormData entry - ${pair[0]}: ${pair[1]} (${typeof pair[1]})`);
        }
        
        await technicianService.updateProfileImage(formDataObj);
        setUploadingImage(false);
      }
      
      toast.success('Perfil actualizado correctamente');
      navigate('/my-profile');
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Error al actualizar el perfil: ' + (error.message || 'Error desconocido'));
      toast.error('Error al actualizar el perfil');
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-2xl mx-auto p-6">
        <div className="bg-red-50 border border-red-200 text-red-800 px-4 py-3 rounded mb-4">
          <p>{error}</p>
        </div>
        <button
          onClick={() => navigate('/my-profile')}
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          Volver a mi perfil
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">Editar Perfil</h1>
      
      <div className="mb-8 bg-white rounded-lg shadow-md p-6 border border-gray-200">
        <label className="block text-gray-700 text-lg font-semibold mb-4">
          Foto de Perfil
        </label>
        <div className="flex items-center space-x-6">
          <div className="w-40 h-40 rounded-full overflow-hidden bg-gray-100 border-4 border-blue-100 shadow-lg">
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="Profile preview"
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center text-gray-400">
                <svg className="w-16 h-16" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </div>
            )}
          </div>
          <div className="flex flex-col space-y-3">
            <label className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg text-sm font-medium transition-colors shadow-sm">
              Cambiar Foto
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="hidden"
              />
            </label>
            {imagePreview && (
              <button
                type="button"
                onClick={handleRemoveImage}
                disabled={submitting}
                className="bg-red-500 hover:bg-red-600 text-white px-6 py-2 rounded-lg text-sm font-medium transition-colors shadow-sm disabled:opacity-50"
              >
                {submitting ? 'Eliminando...' : 'Eliminar Foto'}
              </button>
            )}
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="bg-white rounded-lg shadow-md p-6 border border-gray-200">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">Información Personal</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-gray-700 text-sm font-medium mb-2">
                Biografía
              </label>
              <textarea
                name="bio"
                value={formData.bio}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                rows="4"
                placeholder="Cuéntanos sobre ti y tu experiencia..."
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-700 text-sm font-medium mb-2">
                  Años de Experiencia
                </label>
                <input
                  type="number"
                  name="experience_years"
                  value={formData.experience_years}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  min="0"
                />
              </div>

              <div>
                <label className="block text-gray-700 text-sm font-medium mb-2">
                  Tarifa por Hora ($)
                </label>
                <input
                  type="number"
                  name="hourly_rate"
                  value={formData.hourly_rate}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  min="0"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 border border-gray-200">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">Ubicación</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-gray-700 text-sm font-medium mb-2">
                Ciudad
              </label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-medium mb-2">
                Dirección
              </label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 border border-gray-200">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-lg font-semibold text-gray-800">Disponibilidad</h2>
              <p className="text-sm text-gray-600">Indica si estás disponible para nuevos trabajos</p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="available"
                checked={formData.available}
                onChange={handleChange}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={() => navigate('/my-profile')}
            className="px-6 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
          >
            Cancelar
          </button>
          <button
            type="submit"
            disabled={submitting || uploadingImage}
            className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {submitting || uploadingImage ? 'Guardando...' : 'Guardar Cambios'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditTechnicianProfile; 
