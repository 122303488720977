import { getCookie } from '../utils/cookies';

// API base URL
const API_URL = process.env.REACT_APP_API_URL || '/api';

// Helper function for API requests
const apiRequest = async (endpoint, options = {}) => {
  const url = `${API_URL}${endpoint}`;
  
  // Default headers 
  const headers = {
    // Remove default Content-Type; it will be set based on body
    ...options.headers,
  };
  
  // Determine Content-Type based on body
  let body = options.body;
  if (!(body instanceof FormData)) {
    // If not FormData, assume JSON
    headers['Content-Type'] = 'application/json';
    // Stringify if it's an object and not already stringified
    if (typeof body === 'object' && body !== null) {
      body = JSON.stringify(body);
    }
  } // If it IS FormData, let the browser set the Content-Type header automatically

  
  // Merge options and ensure credentials (cookies) are included
  const requestOptions = {
    ...options,
    headers,
    body, // Use the potentially stringified body
    credentials: 'include' // Tell fetch to send cookies
  };
  
  try {
    const response = await fetch(url, requestOptions);
    
    // Check if response is JSON
    const contentType = response.headers.get('content-type');
    const isJson = contentType && contentType.includes('application/json');
    
    // Parse response
    const data = isJson ? await response.json() : await response.text();
    
    // Handle error responses
    if (!response.ok) {
      // Attempt to parse error message from JSON response, otherwise use generic message
      let errorMessage = 'Error en la solicitud';
      if (isJson && data && data.message) {
        errorMessage = data.message;
      } else if (!isJson && typeof data === 'string' && data.length < 200) {
        // If not JSON, maybe it's a short error string from the server
        errorMessage = data;
      }
      // Include status code in the error for better debugging
      const error = new Error(`${errorMessage} (Status: ${response.status})`);
      error.status = response.status; // Attach status code to the error object
      throw error;
    }
    
    return data;
  } catch (error) {
    console.error('API request failed:', error);
    // Rethrow the error so calling components can handle it
    throw error; 
  }
};

// Auth service
export const authService = {
  login: async (credentials) => {
    return apiRequest('/auth/login', {
      method: 'POST',
      body: JSON.stringify(credentials),
    });
  },
  
  register: async (userData) => {
    return apiRequest('/auth/register', {
      method: 'POST',
      body: JSON.stringify(userData),
    });
  },
  
  checkSession: async () => {
    return apiRequest('/auth/check-session');
  },

  logout: async () => {
    return apiRequest('/auth/logout', {
      method: 'POST'
    });
  },
};

// User service
export const userService = {
  getProfile: async () => {
    return apiRequest('/users/profile');
  },
  
  updateProfile: async (profileData) => {
    return apiRequest('/users/profile', {
      method: 'PUT',
      body: JSON.stringify(profileData),
    });
  },
  
  changePassword: async (passwordData) => {
    return apiRequest('/users/change-password', {
      method: 'PUT',
      body: JSON.stringify(passwordData),
    });
  },
};

// Technician service
export const technicianService = {
  getAll: async () => {
    return apiRequest('/technicians');
  },
  
  getTechnicianById: async (id) => {
    return apiRequest(`/technicians/${id}`);
  },
  
  getByCategory: async (categoryId) => {
    return apiRequest(`/technicians/category/${categoryId}`);
  },
  
  getOwnProfile: async () => {
    return apiRequest('/technicians/profile');
  },
  
  createTechnician: async (technicianData) => {
    return apiRequest('/technicians', {
      method: 'POST',
      body: technicianData, // Assuming FormData or JSON
    });
  },
  
  updateTechnician: async (technicianData) => {
    return apiRequest('/technicians/profile', {
      method: 'PUT',
      body: technicianData, // Assuming JSON
    });
  },

  updateProfileImage: async (formData) => {
    // Note: apiRequest handles FormData Content-Type
    return apiRequest('/technicians/profile/image', {
      method: 'PUT',
      body: formData, // Should be FormData
    });
  },

  removeProfileImage: async () => {
    return apiRequest('/technicians/profile/image', {
      method: 'DELETE',
    });
  },
};

// Category service
export const categoryService = {
  getAll: async () => {
    return apiRequest('/categories');
  },
  
  getById: async (id) => {
    return apiRequest(`/categories/${id}`);
  },
};

// Service request service
export const serviceRequestService = {
  createServiceRequest: async (data) => {
    console.log('API: Creating service request with data:', data);
    try {
      const response = await apiRequest('/service-requests', {
        method: 'POST',
        body: JSON.stringify(data)
      });
      console.log('API: Service request response:', response);
      return response;
    } catch (error) {
      console.error('API: Service request creation failed:', error);
      throw error;
    }
  },
  
  getMyRequests: async () => {
    return apiRequest('/service-requests/my-requests');
  },
  
  updateRequestStatus: async (requestId, status) => {
    return apiRequest(`/service-requests/${requestId}/status`, {
      method: 'PUT',
      body: JSON.stringify({ status })
    });
  },
};

// Review service
export const reviewService = {
  getForTechnician: async (technicianId) => {
    return apiRequest(`/reviews/technician/${technicianId}`);
  },
  
  createReview: async (reviewData) => {
    return apiRequest('/reviews', {
      method: 'POST',
      body: JSON.stringify(reviewData)
    });
  },
  
  checkEligibility: async (technicianId) => {
    return apiRequest(`/reviews/check-eligibility/${technicianId}`);
  }
};

// Admin service (Restored)
export const adminService = {
  // User management
  getAllUsers: async () => {
    return apiRequest('/admin/users');
  },
  
  updateUser: async (userId, userData) => {
    return apiRequest(`/admin/users/${userId}`, {
      method: 'PUT',
      body: userData
    });
  },
  
  deleteUser: async (userId) => {
    return apiRequest(`/admin/users/${userId}`, {
      method: 'DELETE'
    });
  },
  
  // Category management
  getAllCategories: async () => {
    return apiRequest('/admin/categories');
  },
  
  createCategory: async (categoryData) => {
    return apiRequest('/admin/categories', {
      method: 'POST',
      body: categoryData
    });
  },
  
  updateCategory: async (categoryId, categoryData) => {
    return apiRequest(`/admin/categories/${categoryId}`, {
      method: 'PUT',
      body: categoryData
    });
  },
  
  deleteCategory: async (categoryId) => {
    return apiRequest(`/admin/categories/${categoryId}`, {
      method: 'DELETE'
    });
  },
  
  // Technician management
  getAllTechnicians: async () => {
    return apiRequest('/admin/technicians');
  },
  
  getTechnicianDetails: async (technicianId) => {
    return apiRequest(`/admin/technicians/${technicianId}`);
  },
  
  updateTechnician: async (technicianId, technicianData) => {
    return apiRequest(`/admin/technicians/${technicianId}`, {
      method: 'PUT',
      body: JSON.stringify(technicianData)
    });
  },
  
  // Reports
  generateUserReport: async () => {
    return apiRequest('/admin/reports/users');
  },
  
  generateServiceReport: async (params = {}) => {
    const queryParams = new URLSearchParams();
    if (params.start_date) queryParams.append('start_date', params.start_date);
    if (params.end_date) queryParams.append('end_date', params.end_date);
    
    const queryString = queryParams.toString();
    const endpoint = queryString ? `/admin/reports/services?${queryString}` : '/admin/reports/services';
    
    return apiRequest(endpoint);
  },
  
  generateRevenueReport: async (params = {}) => {
    const queryParams = new URLSearchParams();
    if (params.year) queryParams.append('year', params.year);
    if (params.month) queryParams.append('month', params.month);
    
    const queryString = queryParams.toString();
    const endpoint = queryString ? `/admin/reports/revenue?${queryString}` : '/admin/reports/revenue';
    
    return apiRequest(endpoint);
  }
};
