import React, { createContext, useState, useContext, useEffect } from 'react';
import { authService } from '../services/api';

// Create context with a default empty value
const AuthContext = createContext({
  user: null,
  loading: false,
  error: null,
  login: () => {},
  logout: () => {},
  isAuthenticated: false,
  isTechnician: false
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Check if user is logged in (could be expanded to check with the server)
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      try {
        setUser(JSON.parse(storedUser));
      } catch (err) {
        console.error('Error parsing stored user data', err);
        localStorage.removeItem('user');
      }
    }
    setLoading(false);
  }, []);

  const login = async (emailOrUser, password) => {
    setLoading(true);
    setError(null);
    try {
      // Handle both scenarios: direct user object or email/password credentials
      if (typeof emailOrUser === 'object' && !password) {
        // Direct user object passed (e.g. from existing auth response)
        localStorage.setItem('user', JSON.stringify(emailOrUser));
        setUser(emailOrUser);
        return { user: emailOrUser };
      } else {
        // Email and password passed
        const response = await authService.login({ 
          email: emailOrUser, 
          password 
        });
        if (response && response.user) {
          localStorage.setItem('user', JSON.stringify(response.user));
          setUser(response.user);
        }
        return response;
      }
    } catch (err) {
      setError(err.message || 'Error during login');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    setLoading(true);
    try {
      await authService.logout();
      localStorage.removeItem('user');
      setUser(null);
    } catch (err) {
      console.error('Error during logout:', err);
    } finally {
      setLoading(false);
    }
  };

  const value = {
    user,
    loading,
    error,
    login,
    logout,
    isAuthenticated: !!user,
    isTechnician: user?.role === 'technician'
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

// Export the context and provider together
export { AuthContext };
export default AuthProvider; 