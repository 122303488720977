import React, { useEffect } from 'react';
import { Outlet, NavLink, useNavigate } from 'react-router-dom';
import { Users, Briefcase, List, BarChart2, LogOut, Home } from 'react-feather';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-hot-toast';

const AdminDashboard = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is logged in and is an admin
    if (!user) {
      toast.error('Debe iniciar sesión para acceder');
      navigate('/login');
      return;
    }

    if (user.role !== 'admin') {
      toast.error('No tiene permisos para acceder a esta página');
      navigate('/');
      return;
    }
  }, [user, navigate]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
      toast.error('Error al cerrar sesión');
    }
  };

  if (!user || user.role !== 'admin') {
    return null; // Don't render anything while redirecting
  }

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="w-64 bg-white shadow-md">
        <div className="p-4 border-b">
          <h1 className="text-xl font-semibold text-blue-600">Admin Dashboard</h1>
          <p className="text-sm text-gray-500">Bienvenido, {user.name}</p>
        </div>
        
        <nav className="mt-4">
          <NavLink 
            to="/admin/reports" 
            className={({ isActive }) => 
              `flex items-center p-3 text-gray-600 hover:bg-blue-50 hover:text-blue-600 transition-colors ${
                isActive ? 'bg-blue-50 text-blue-600 border-r-4 border-blue-600' : ''
              }`
            }
          >
            <BarChart2 className="h-5 w-5 mr-2" />
            <span>Reportes</span>
          </NavLink>
          
          <NavLink 
            to="/admin/users" 
            className={({ isActive }) => 
              `flex items-center p-3 text-gray-600 hover:bg-blue-50 hover:text-blue-600 transition-colors ${
                isActive ? 'bg-blue-50 text-blue-600 border-r-4 border-blue-600' : ''
              }`
            }
          >
            <Users className="h-5 w-5 mr-2" />
            <span>Usuarios</span>
          </NavLink>
          
          <NavLink 
            to="/admin/categories" 
            className={({ isActive }) => 
              `flex items-center p-3 text-gray-600 hover:bg-blue-50 hover:text-blue-600 transition-colors ${
                isActive ? 'bg-blue-50 text-blue-600 border-r-4 border-blue-600' : ''
              }`
            }
          >
            <List className="h-5 w-5 mr-2" />
            <span>Categorías</span>
          </NavLink>
          
          <NavLink 
            to="/admin/technicians" 
            className={({ isActive }) => 
              `flex items-center p-3 text-gray-600 hover:bg-blue-50 hover:text-blue-600 transition-colors ${
                isActive ? 'bg-blue-50 text-blue-600 border-r-4 border-blue-600' : ''
              }`
            }
          >
            <Briefcase className="h-5 w-5 mr-2" />
            <span>Técnicos</span>
          </NavLink>
          
          <div className="mt-auto border-t p-3">
            <button 
              onClick={handleLogout}
              className="flex items-center text-gray-600 hover:text-red-600 transition-colors"
            >
              <LogOut className="h-5 w-5 mr-2" />
              <span>Cerrar sesión</span>
            </button>
            
            <NavLink 
              to="/" 
              className="flex items-center mt-3 text-gray-600 hover:text-blue-600 transition-colors"
            >
              <Home className="h-5 w-5 mr-2" />
              <span>Volver al inicio</span>
            </NavLink>
          </div>
        </nav>
      </div>
      
      {/* Main content */}
      <div className="flex-1 overflow-auto">
        <header className="bg-white shadow-sm p-4">
          <h2 className="text-xl font-semibold text-gray-800">Panel de Administración</h2>
        </header>
        
        <div className="p-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard; 