import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Search as SearchIcon, MapPin, Star, Filter, ArrowLeft, Settings, ChevronDown } from 'lucide-react';
import axios from 'axios';

const Search = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(searchParams.get('query') || '');
  const [selectedCategory, setSelectedCategory] = useState(searchParams.get('category_id') || '');
  const [selectedCity, setSelectedCity] = useState(searchParams.get('city') || '');
  const [onlyAvailable, setOnlyAvailable] = useState(searchParams.get('available') === 'true');
  const [technicians, setTechnicians] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([
    'Santiago', 'Santo Domingo', 'La Vega', 'Puerto Plata', 'San Francisco de Macorís', 'San Cristóbal'
  ]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesResponse = await axios.get('/api/technicians/categories');
	      if (categoriesResponse.data && Array.isArray(categoriesResponse.data.categories)) {
          setCategories(categoriesResponse.data.categories);
        }
      } catch (err) {
        console.error('Error fetching categories:', err);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchTechnicians = async () => {
      try {
        setLoading(true);
        
        // Build query parameters
        const queryParams = new URLSearchParams();
        
        // Check if we have a search query
        if (searchQuery) {
          // If using the search endpoint
          if (searchQuery.trim() !== '') {
            queryParams.append('query', searchQuery);
            console.log('Using search endpoint with query:', searchQuery);
            
            // Add other filters if applicable
            if (selectedCategory) queryParams.append('category_id', selectedCategory);
            if (selectedCity) queryParams.append('city', selectedCity);
            if (onlyAvailable) queryParams.append('available', 'true');
            
            console.log('Search endpoint params:', queryParams.toString());
            
            const searchResponse = await axios.get(`http://localhost:8000/api/technicians/search?${queryParams.toString()}`);
            console.log('Search API Response:', searchResponse.data);
            
            if (searchResponse.data && Array.isArray(searchResponse.data.technicians)) {
              setTechnicians(searchResponse.data.technicians);
            } else if (Array.isArray(searchResponse.data)) {
              setTechnicians(searchResponse.data);
            } else {
              setTechnicians([]);
            }
          } else {
            // Use the regular endpoint with filters
            fetchWithFilters();
          }
        } else {
          // Use the regular endpoint with filters
          fetchWithFilters();
        }
        
        setError(null);
      } catch (err) {
        console.error('Error fetching technicians:', err);
        setError(`No se pudieron cargar los datos: ${err.message}`);
        setTechnicians([]);
      } finally {
        setLoading(false);
      }
    };
    
    const fetchWithFilters = async () => {
      const queryParams = new URLSearchParams();
      if (selectedCategory) queryParams.append('category_id', selectedCategory);
      if (selectedCity) queryParams.append('city', selectedCity);
      if (onlyAvailable) queryParams.append('available', 'true');
      
      console.log('Filter endpoint params:', queryParams.toString());
      
      const techniciansResponse = await axios.get(`http://localhost:8000/api/technicians?${queryParams.toString()}`);
      console.log('Filter API Response:', techniciansResponse.data);
      
      if (techniciansResponse.data && Array.isArray(techniciansResponse.data.technicians)) {
        setTechnicians(techniciansResponse.data.technicians);
      } else if (Array.isArray(techniciansResponse.data)) {
        setTechnicians(techniciansResponse.data);
      } else {
        setTechnicians([]);
      }
    };

    // If location.search has changed, fetch technicians
    fetchTechnicians();
  }, [location.search, searchQuery, selectedCategory, selectedCity, onlyAvailable]);

  const handleSearch = (e) => {
    e.preventDefault();
    
    // Update URL with search parameters
    const queryParams = new URLSearchParams();
    if (searchQuery) queryParams.set('query', searchQuery);
    if (selectedCategory) queryParams.set('category_id', selectedCategory);
    if (selectedCity) queryParams.set('city', selectedCity);
    if (onlyAvailable) queryParams.set('available', 'true');
    
    navigate(`/search?${queryParams.toString()}`);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header with back button */}
      <header className="bg-blue-600 text-white shadow-md">
        <div className="container mx-auto px-4 py-3 flex items-center">
          <button 
            onClick={() => navigate('/')}
            className="flex items-center text-white"
          >
            <ArrowLeft className="mr-2" />
            <span>Volver</span>
          </button>
          <h1 className="text-xl font-bold mx-auto">Buscar Técnicos</h1>
        </div>
      </header>

      {/* Search and Filters Section */}
      <div className="bg-white shadow-md">
        <div className="container mx-auto px-4 py-4">
          <form onSubmit={handleSearch}>
            <div className="flex flex-col md:flex-row gap-4">
              <div className="flex-grow relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  placeholder="Buscar técnicos..."
                  className="pl-10 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <button
                type="button"
                className="md:hidden flex items-center text-blue-600 border border-blue-600 rounded-md px-4 py-2 font-medium"
                onClick={() => setIsFiltersOpen(!isFiltersOpen)}
              >
                <Filter className="h-5 w-5 mr-2" />
                Filtros
              </button>
              <div className="hidden md:flex space-x-4">
                <select
                  className="bg-white border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option value="">Todas las categorías</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <select
                  className="bg-white border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                >
                  <option value="">Todas las ciudades</option>
                  {cities.map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="available"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    checked={onlyAvailable}
                    onChange={(e) => setOnlyAvailable(e.target.checked)}
                  />
                  <label htmlFor="available" className="ml-2 block text-sm text-gray-700">
                    Solo disponibles
                  </label>
                </div>
                <button
                  type="submit"
                  className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md"
                >
                  Buscar
                </button>
              </div>
            </div>
            
            {/* Mobile filters */}
            {isFiltersOpen && (
              <div className="mt-4 md:hidden space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Categoría</label>
                  <select
                    className="w-full bg-white border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    <option value="">Todas las categorías</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Ciudad</label>
                  <select
                    className="w-full bg-white border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                  >
                    <option value="">Todas las ciudades</option>
                    {cities.map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="available-mobile"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    checked={onlyAvailable}
                    onChange={(e) => setOnlyAvailable(e.target.checked)}
                  />
                  <label htmlFor="available-mobile" className="ml-2 block text-sm text-gray-700">
                    Solo disponibles
                  </label>
                </div>
                <button
                  type="submit"
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-md"
                >
                  Aplicar filtros
                </button>
              </div>
            )}
          </form>
        </div>
      </div>

      {/* Results Section */}
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">
            {loading ? 'Buscando técnicos...' : `${technicians.length} técnicos encontrados`}
          </h2>
          <div className="flex items-center text-sm text-gray-600">
            <span className="mr-2">Ordenar por:</span>
            <select
              className="bg-white border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              defaultValue="rating"
            >
              <option value="rating">Valoración</option>
              <option value="price_low">Precio: Menor a Mayor</option>
              <option value="price_high">Precio: Mayor a Menor</option>
              <option value="experience">Experiencia</option>
            </select>
          </div>
        </div>
        
        {error && (
          <div className="bg-red-100 text-red-700 p-4 rounded-md mb-6">
            {error}
          </div>
        )}
        
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
          </div>
        ) : technicians.length === 0 ? (
          <div className="bg-yellow-50 text-yellow-700 p-6 rounded-md text-center">
            <h3 className="font-semibold mb-2">No se encontraron técnicos</h3>
            <p>Intenta con otros criterios de búsqueda o consulta todas las categorías disponibles.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {technicians.map((tech) => (
              <div key={tech.id} className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200">
                <div className="p-6">
                  <div className="flex items-start space-x-4">
                    {/* Technician profile image */}
                    <div className="flex-shrink-0">
                      <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-200 flex items-center justify-center">
                        {tech.profile_image ? (
                          <img 
                            src={`http://localhost:8000/uploads/${tech.profile_image}`} 
                            alt={tech.name}
                            className="w-full h-full object-cover"
                            onError={(e) => {
                              e.target.onerror = null; 
                              e.target.src = 'https://via.placeholder.com/64?text=T';
                            }}
                          />
                        ) : (
                          <span className="text-2xl text-gray-400">{tech.name ? tech.name.charAt(0).toUpperCase() : 'T'}</span>
                        )}
                      </div>
                    </div>
                    
                    {/* Technician info */}
                    <div className="flex-1">
                      <div className="flex justify-between">
                        <h3 className="text-lg font-semibold text-gray-800">{tech.name}</h3>
                        <div className="flex items-center text-amber-500">
                          <Star className="h-5 w-5 fill-current" />
                          <span className="ml-1">{tech.average_rating && typeof tech.average_rating === 'number' ? tech.average_rating.toFixed(1) : '0.0'}</span>
                        </div>
                      </div>
                      <p className="text-sm text-blue-600 mt-1">{tech.category_name || 'Sin categoría'}</p>
                      <div className="flex items-center text-gray-600 mt-2">
                        <MapPin className="h-4 w-4" />
                        <span className="ml-1 text-sm">{tech.city || 'Santiago'}</span>
                      </div>
                    </div>
                  </div>
                  
                  {tech.bio && (
                    <p className="mt-3 text-sm text-gray-600 line-clamp-2">
                      {tech.bio}
                    </p>
                  )}
                  
                  <div className="mt-4 flex justify-between items-center">
                    <div>
                      <span className="block text-xs text-gray-500">Tarifa por hora</span>
                      <span className="text-lg font-bold text-gray-800">RD${tech.hourly_rate || '0'}</span>
                    </div>
                    <div>
                      <span className="block text-xs text-gray-500">Experiencia</span>
                      <span className="text-sm text-gray-700">{tech.experience_years || 0} años</span>
                    </div>
                    <button
                      onClick={() => navigate(`/technicians/${tech.id}`)}
                      className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-md"
                    >
                      Ver Perfil
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Search; 
