import React, { useState, useEffect } from 'react';
import { Search, Eye, Edit2, Star } from 'react-feather';
import { toast } from 'react-hot-toast';
import { adminService } from '../../services/api';

const TechnicianManagement = () => {
  const [technicians, setTechnicians] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTechnicians, setFilteredTechnicians] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [technicianDetails, setTechnicianDetails] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [editFormData, setEditFormData] = useState({
    bio: '',
    hourly_rate: '',
    experience_years: '',
    city: '',
    address: '',
    available: true,
    category_id: ''
  });
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    fetchTechnicians();
    fetchCategories();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filtered = technicians.filter(
        tech => 
          tech.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tech.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (tech.category_name && tech.category_name.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredTechnicians(filtered);
    } else {
      setFilteredTechnicians(technicians);
    }
  }, [technicians, searchTerm]);

  const fetchTechnicians = async () => {
    try {
      setLoading(true);
      const response = await adminService.getAllTechnicians();
      const fetchedUsers = response?.users || [];
      setTechnicians(fetchedUsers);
      setFilteredTechnicians(fetchedUsers);
    } catch (error) {
      console.error('Error fetching technicians:', error);
      toast.error('Error al cargar los técnicos');
      setTechnicians([]);
      setFilteredTechnicians([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      setLoadingCategories(true);
      const response = await adminService.getAllCategories();
      setCategories(response?.categories || []);
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoadingCategories(false);
    }
  };

  const viewTechnicianDetails = async (technician, forEdit = false) => {
    try {
      setLoadingDetails(true);
      setShowDetails(!forEdit);
      
      // Use technician_id if available, otherwise use user ID
      const idToUse = technician.technician_id || technician.id;
      console.log('viewTechnicianDetails - technician object:', technician);
      console.log('viewTechnicianDetails - Using ID:', idToUse, 'Type:', typeof idToUse);
      
      const response = await adminService.getTechnicianDetails(idToUse);
      console.log('API response:', response);
      setTechnicianDetails(response);
      
      if (forEdit) {
        // Pre-fill the edit form with technician details
        setEditFormData({
          bio: response.technician.bio || '',
          hourly_rate: response.technician.hourly_rate || '',
          experience_years: response.technician.experience_years || '',
          city: response.technician.city || '',
          address: response.technician.address || '',
          available: response.technician.available === 1,
          category_id: response.technician.category_id || ''
        });
        setShowEditForm(true);
      }
    } catch (error) {
      console.error('Error fetching technician details:', error);
      toast.error('Error al cargar los detalles del técnico');
      setShowDetails(false);
    } finally {
      setLoadingDetails(false);
    }
  };

  const editTechnician = (technician) => {
    // First fetch the technician details
    viewTechnicianDetails(technician, true);
  };

  const handleEditFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    
    if (!technicianDetails) return;
    
    try {
      setSubmitting(true);
      
      // Format the data for API
      const updateData = {
        ...editFormData,
        hourly_rate: parseFloat(editFormData.hourly_rate) || 0,
        experience_years: parseInt(editFormData.experience_years) || 0,
        category_id: editFormData.category_id ? parseInt(editFormData.category_id) : null
      };
      
      await adminService.updateTechnician(technicianDetails.technician.id, updateData);
      
      toast.success('Perfil del técnico actualizado exitosamente');
      setShowEditForm(false);
      fetchTechnicians(); // Refresh the list
    } catch (error) {
      console.error('Error updating technician profile:', error);
      toast.error(error.message || 'Error al actualizar el perfil del técnico');
    } finally {
      setSubmitting(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const hasFilteredTechnicians = Array.isArray(filteredTechnicians) && filteredTechnicians.length > 0;

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Administración de Técnicos</h2>
        <div className="relative">
          <input
            type="text"
            placeholder="Buscar técnico..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="px-4 py-2 pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>
      
      {!hasFilteredTechnicians && !loading ? (
        <p className="text-gray-500 text-center py-4">
          {searchTerm ? 'No se encontraron técnicos que coincidan con la búsqueda' : 'No hay técnicos para mostrar'}
        </p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-100 text-gray-700">
              <tr>
                <th className="py-3 px-4 text-left">ID</th>
                <th className="py-3 px-4 text-left">Nombre</th>
                <th className="py-3 px-4 text-left">Email</th>
                <th className="py-3 px-4 text-left">Categoría</th>
                <th className="py-3 px-4 text-left">Ciudad</th>
                <th className="py-3 px-4 text-left">Tarifa</th>
                <th className="py-3 px-4 text-left">Rating</th>
                <th className="py-3 px-4 text-center">Acciones</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {(filteredTechnicians || []).map((technician) => (
                <tr key={technician.id} className="hover:bg-gray-50">
                  <td className="py-3 px-4">{technician.id}</td>
                  <td className="py-3 px-4">{technician.name}</td>
                  <td className="py-3 px-4">{technician.email}</td>
                  <td className="py-3 px-4">{technician.category_name || '-'}</td>
                  <td className="py-3 px-4">{technician.city || '-'}</td>
                  <td className="py-3 px-4">${technician.hourly_rate || 0}/hr</td>
                  <td className="py-3 px-4">
                    <div className="flex items-center">
                      <Star className="h-4 w-4 text-yellow-400 mr-1" />
                      <span>{technician.average_rating ? parseFloat(technician.average_rating).toFixed(1) : 'N/A'}</span>
                    </div>
                  </td>
                  <td className="py-3 px-4 flex justify-center space-x-2">
                    {technician.is_technician > 0 ? (
                      <>
                        <button
                          onClick={() => viewTechnicianDetails(technician)}
                          className="text-blue-600 hover:text-blue-800"
                          title="Ver Detalles"
                        >
                          <Eye className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() => editTechnician(technician)}
                          className="text-green-600 hover:text-green-800"
                          title="Editar Perfil"
                        >
                          <Edit2 className="h-5 w-5" />
                        </button>
                      </>
                    ) : (
                      <span className="text-gray-400 cursor-not-allowed" title="No tiene perfil de técnico">
                        <Eye className="h-5 w-5" />
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      
      {/* Technician Details Modal */}
      {showDetails && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl p-6 max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Detalles del Técnico</h3>
              <button
                onClick={() => setShowDetails(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            
            {loadingDetails ? (
              <div className="flex justify-center items-center p-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              </div>
            ) : technicianDetails ? (
              <div className="space-y-6">
                <div className="flex flex-col md:flex-row gap-6">
                  <div className="flex-shrink-0">
                    {technicianDetails.technician.profile_image ? (
                      <img
                        src={`/uploads/${technicianDetails.technician.profile_image}`}
                        alt={technicianDetails.user.name}
                        className="w-32 h-32 object-cover rounded-lg"
                      />
                    ) : (
                      <div className="w-32 h-32 flex items-center justify-center bg-gray-200 rounded-lg text-gray-400">
                        <svg className="h-12 w-12" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                      </div>
                    )}
                  </div>
                  
                  <div className="flex-1">
                    <h4 className="text-xl font-semibold mb-1">{technicianDetails.user.name}</h4>
                    <p className="text-gray-600 mb-2">{technicianDetails.user.email}</p>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                      <div>
                        <p className="text-sm text-gray-500">Teléfono</p>
                        <p className="font-medium">{technicianDetails.user.phone || '-'}</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Ciudad</p>
                        <p className="font-medium">{technicianDetails.technician.city || '-'}</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Dirección</p>
                        <p className="font-medium">{technicianDetails.technician.address || '-'}</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Tarifa por Hora</p>
                        <p className="font-medium">${technicianDetails.technician.hourly_rate || 0}</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Experiencia</p>
                        <p className="font-medium">{technicianDetails.technician.experience_years || 0} años</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Fecha de Registro</p>
                        <p className="font-medium">{formatDate(technicianDetails.technician.created_at)}</p>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div>
                  <h5 className="font-semibold mb-2">Biografía</h5>
                  <p className="text-gray-700 whitespace-pre-line">{technicianDetails.technician.bio || 'No hay biografía disponible.'}</p>
                </div>
                
                <div>
                  <h5 className="font-semibold mb-2">Habilidades</h5>
                  {technicianDetails.skills && technicianDetails.skills.length > 0 ? (
                    <div className="flex flex-wrap gap-2">
                      {technicianDetails.skills.map((skill, index) => (
                        <span
                          key={index}
                          className="px-3 py-1 bg-blue-100 text-blue-700 rounded-full text-sm"
                        >
                          {skill.skill_name}
                        </span>
                      ))}
                    </div>
                  ) : (
                    <p className="text-gray-500">No hay habilidades registradas.</p>
                  )}
                </div>
              </div>
            ) : (
              <p className="text-gray-500 text-center py-4">No se pudieron cargar los detalles del técnico.</p>
            )}
          </div>
        </div>
      )}
      
      {/* Edit Form Modal */}
      {showEditForm && technicianDetails && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl p-6 max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Editar Perfil de Técnico</h3>
              <button
                onClick={() => setShowEditForm(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            
            {loadingDetails ? (
              <div className="flex justify-center items-center p-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <form onSubmit={handleEditSubmit} className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-gray-700 text-sm font-medium mb-2">
                      Nombre del Técnico
                    </label>
                    <div className="text-gray-600 px-3 py-2 border rounded-md border-gray-300 bg-gray-50">
                      {technicianDetails.user.name}
                    </div>
                  </div>
                  
                  <div>
                    <label className="block text-gray-700 text-sm font-medium mb-2">
                      Email
                    </label>
                    <div className="text-gray-600 px-3 py-2 border rounded-md border-gray-300 bg-gray-50">
                      {technicianDetails.user.email}
                    </div>
                  </div>
                  
                  <div>
                    <label htmlFor="category_id" className="block text-gray-700 text-sm font-medium mb-2">
                      Categoría
                    </label>
                    <select
                      id="category_id"
                      name="category_id"
                      value={editFormData.category_id}
                      onChange={handleEditFormChange}
                      className="w-full px-3 py-2 border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <option value="">Seleccionar categoría</option>
                      {categories.map(cat => (
                        <option key={cat.id} value={cat.id}>
                          {cat.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  
                  <div>
                    <label htmlFor="hourly_rate" className="block text-gray-700 text-sm font-medium mb-2">
                      Tarifa por Hora ($)
                    </label>
                    <input
                      id="hourly_rate"
                      name="hourly_rate"
                      type="number"
                      min="0"
                      value={editFormData.hourly_rate}
                      onChange={handleEditFormChange}
                      className="w-full px-3 py-2 border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                  
                  <div>
                    <label htmlFor="experience_years" className="block text-gray-700 text-sm font-medium mb-2">
                      Años de Experiencia
                    </label>
                    <input
                      id="experience_years"
                      name="experience_years"
                      type="number"
                      min="0"
                      value={editFormData.experience_years}
                      onChange={handleEditFormChange}
                      className="w-full px-3 py-2 border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                  
                  <div>
                    <label htmlFor="city" className="block text-gray-700 text-sm font-medium mb-2">
                      Ciudad
                    </label>
                    <input
                      id="city"
                      name="city"
                      type="text"
                      value={editFormData.city}
                      onChange={handleEditFormChange}
                      className="w-full px-3 py-2 border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                  
                  <div>
                    <label htmlFor="address" className="block text-gray-700 text-sm font-medium mb-2">
                      Dirección
                    </label>
                    <input
                      id="address"
                      name="address"
                      type="text"
                      value={editFormData.address}
                      onChange={handleEditFormChange}
                      className="w-full px-3 py-2 border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                  
                  <div className="flex items-center space-x-2">
                    <input
                      id="available"
                      name="available"
                      type="checkbox"
                      checked={editFormData.available}
                      onChange={handleEditFormChange}
                      className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <label htmlFor="available" className="text-sm text-gray-700">
                      Disponible para trabajar
                    </label>
                  </div>
                </div>
                
                <div>
                  <label htmlFor="bio" className="block text-gray-700 text-sm font-medium mb-2">
                    Biografía
                  </label>
                  <textarea
                    id="bio"
                    name="bio"
                    value={editFormData.bio}
                    onChange={handleEditFormChange}
                    rows="4"
                    className="w-full px-3 py-2 border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  ></textarea>
                </div>
                
                <div className="flex justify-end space-x-3 pt-4">
                  <button
                    type="button"
                    onClick={() => setShowEditForm(false)}
                    className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    disabled={submitting}
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
                  >
                    {submitting ? 'Guardando...' : 'Guardar Cambios'}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TechnicianManagement; 