import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ArrowLeft, Star, MessageSquare, Check, Clock, X } from 'react-feather';
import { useAuth } from '../contexts/AuthContext';
import { serviceRequestService } from '../services/api';
import { toast } from 'react-hot-toast';

const MyServiceRequests = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [serviceRequests, setServiceRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      toast.error('Debe iniciar sesión para ver sus solicitudes');
      navigate('/login');
      return;
    }

    fetchServiceRequests();
  }, [user, navigate]);

  const fetchServiceRequests = async () => {
    try {
      const response = await serviceRequestService.getMyRequests();
      setServiceRequests(response.serviceRequests || []);
    } catch (error) {
      console.error('Error fetching service requests:', error);
      toast.error('Error al cargar las solicitudes de servicio');
    } finally {
      setLoading(false);
    }
  };

  const updateServiceStatus = async (id, status) => {
    try {
      await serviceRequestService.updateRequestStatus(id, status);
      toast.success('Estado de la solicitud actualizado exitosamente');
      fetchServiceRequests(); // Refresh the list
    } catch (error) {
      console.error('Error updating service request:', error);
      toast.error('Error al actualizar la solicitud');
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'pending':
        return <span className="px-2 py-1 bg-yellow-100 text-yellow-800 rounded-full text-xs">Pendiente</span>;
      case 'accepted':
        return <span className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-xs">Aceptada</span>;
      case 'completed':
        return <span className="px-2 py-1 bg-green-100 text-green-800 rounded-full text-xs">Completada</span>;
      case 'cancelled':
        return <span className="px-2 py-1 bg-red-100 text-red-800 rounded-full text-xs">Cancelada</span>;
      default:
        return <span className="px-2 py-1 bg-gray-100 text-gray-800 rounded-full text-xs">{status}</span>;
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-4xl mx-auto px-4">
        <div className="mb-6">
          <button 
            onClick={() => navigate(-1)} 
            className="flex items-center text-blue-600 hover:underline"
          >
            <ArrowLeft className="mr-1 h-4 w-4" />
            Volver
          </button>
        </div>

        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="bg-blue-600 px-6 py-4">
            <h1 className="text-2xl font-bold text-white">Mis Solicitudes de Servicio</h1>
          </div>
          
          <div className="p-6">
            {serviceRequests.length === 0 ? (
              <div className="text-center py-8">
                <p className="text-gray-500 mb-4">No tienes solicitudes de servicio activas.</p>
                <button
                  onClick={() => navigate('/search')}
                  className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                  Buscar Técnicos
                </button>
              </div>
            ) : (
              <div className="space-y-6">
                {serviceRequests.map((request) => (
                  <div key={request.id} className="border rounded-lg overflow-hidden">
                    <div className="bg-gray-50 px-4 py-3 flex justify-between items-center">
                      <div>
                        <h3 className="font-medium">
                          {user.role === 'customer' ? request.technician_name : request.customer_name}
                        </h3>
                        <p className="text-sm text-gray-500">
                          {new Date(request.request_date).toLocaleDateString()}
                        </p>
                      </div>
                      {getStatusLabel(request.status)}
                    </div>
                    
                    <div className="p-4">
                      <p className="text-gray-700 mb-4">{request.service_description}</p>
                      
                      <div className="text-sm text-gray-600 mb-4">
                        <p><strong>Ubicación:</strong> {request.service_location}</p>
                        {user.role === 'customer' && (
                          <p><strong>Categoría:</strong> {request.category_name}</p>
                        )}
                      </div>
                      
                      <div className="flex flex-wrap gap-2 mt-4">
                        {/* Customer actions */}
                        {user.role === 'customer' && (
                          <>
                            {request.status === 'pending' && (
                              <button
                                onClick={() => updateServiceStatus(request.id, 'cancelled')}
                                className="px-3 py-1 bg-red-100 text-red-700 rounded hover:bg-red-200 text-sm flex items-center"
                              >
                                <X className="h-4 w-4 mr-1" />
                                Cancelar
                              </button>
                            )}
                            
                            {request.status === 'completed' && request.has_review === 0 && (
                              <Link
                                to={`/reviews/create/${request.technician_id}`}
                                className="px-3 py-1 bg-green-100 text-green-700 rounded hover:bg-green-200 text-sm flex items-center"
                              >
                                <Star className="h-4 w-4 mr-1" />
                                Escribir Reseña
                              </Link>
                            )}
                            
                            {request.status === 'completed' && request.has_review > 0 && (
                              <span className="px-3 py-1 bg-gray-100 text-gray-700 rounded text-sm flex items-center">
                                <MessageSquare className="h-4 w-4 mr-1" />
                                Reseña Enviada
                              </span>
                            )}
                          </>
                        )}
                        
                        {/* Technician actions */}
                        {user.role === 'technician' && (
                          <>
                            {request.status === 'pending' && (
                              <>
                                <button
                                  onClick={() => updateServiceStatus(request.id, 'accepted')}
                                  className="px-3 py-1 bg-blue-100 text-blue-700 rounded hover:bg-blue-200 text-sm flex items-center"
                                >
                                  <Check className="h-4 w-4 mr-1" />
                                  Aceptar
                                </button>
                                <button
                                  onClick={() => updateServiceStatus(request.id, 'cancelled')}
                                  className="px-3 py-1 bg-red-100 text-red-700 rounded hover:bg-red-200 text-sm flex items-center"
                                >
                                  <X className="h-4 w-4 mr-1" />
                                  Rechazar
                                </button>
                              </>
                            )}
                            
                            {request.status === 'accepted' && (
                              <button
                                onClick={() => updateServiceStatus(request.id, 'completed')}
                                className="px-3 py-1 bg-green-100 text-green-700 rounded hover:bg-green-200 text-sm flex items-center"
                              >
                                <Check className="h-4 w-4 mr-1" />
                                Marcar Completado
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyServiceRequests; 