import React, { useState, useEffect } from 'react';
import { Users, Briefcase, DollarSign } from 'react-feather';
import { toast } from 'react-hot-toast';
import { adminService } from '../../services/api';

const Reports = () => {
  const [activeReport, setActiveReport] = useState('users');
  const [userReport, setUserReport] = useState(null);
  const [serviceReport, setServiceReport] = useState(null);
  const [revenueReport, setRevenueReport] = useState(null);
  const [loadingUserReport, setLoadingUserReport] = useState(false);
  const [loadingServiceReport, setLoadingServiceReport] = useState(false);
  const [loadingRevenueReport, setLoadingRevenueReport] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });
  const [yearMonth, setYearMonth] = useState({
    year: new Date().getFullYear().toString(),
    month: ''
  });

  useEffect(() => {
    loadUserReport();
  }, []);

  const loadUserReport = async () => {
    try {
      setLoadingUserReport(true);
      const report = await adminService.generateUserReport();
      setUserReport(report);
    } catch (error) {
      console.error('Error loading user report:', error);
      toast.error('Error al cargar el reporte de usuarios');
    } finally {
      setLoadingUserReport(false);
    }
  };

  const loadServiceReport = async () => {
    try {
      setLoadingServiceReport(true);
      const report = await adminService.generateServiceReport({
        start_date: dateRange.startDate || undefined,
        end_date: dateRange.endDate || undefined
      });
      setServiceReport(report);
    } catch (error) {
      console.error('Error loading service report:', error);
      toast.error('Error al cargar el reporte de servicios');
    } finally {
      setLoadingServiceReport(false);
    }
  };

  const loadRevenueReport = async () => {
    try {
      setLoadingRevenueReport(true);
      const report = await adminService.generateRevenueReport({
        year: yearMonth.year || undefined,
        month: yearMonth.month || undefined
      });
      setRevenueReport(report);
    } catch (error) {
      console.error('Error loading revenue report:', error);
      toast.error('Error al cargar el reporte de ingresos');
    } finally {
      setLoadingRevenueReport(false);
    }
  };

  const handleTabChange = (tab) => {
    setActiveReport(tab);
    if (tab === 'users' && !userReport) {
      loadUserReport();
    } else if (tab === 'services' && !serviceReport) {
      loadServiceReport();
    } else if (tab === 'revenue' && !revenueReport) {
      loadRevenueReport();
    }
  };

  const handleDateRangeChange = (e) => {
    const { name, value } = e.target;
    setDateRange({
      ...dateRange,
      [name]: value
    });
  };

  const handleYearMonthChange = (e) => {
    const { name, value } = e.target;
    setYearMonth({
      ...yearMonth,
      [name]: value
    });
  };

  const renderUserReport = () => {
    if (loadingUserReport) {
      return (
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      );
    }

    if (!userReport) {
      return (
        <p className="text-gray-500 text-center py-4">No hay datos disponibles.</p>
      );
    }

    return (
      <div className="space-y-6">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-4">Estadísticas de Usuarios</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            <div className="bg-blue-50 p-4 rounded-lg">
              <p className="text-sm text-blue-600 mb-1">Total de Usuarios</p>
              <p className="text-2xl font-bold">{userReport.totalUsers}</p>
            </div>
            
            {userReport.userStats.map((stat) => (
              <div 
                key={stat.role} 
                className={`p-4 rounded-lg ${
                  stat.role === 'admin' ? 'bg-purple-50' : 
                  stat.role === 'technician' ? 'bg-green-50' : 'bg-yellow-50'
                }`}
              >
                <p className={`text-sm mb-1 ${
                  stat.role === 'admin' ? 'text-purple-600' : 
                  stat.role === 'technician' ? 'text-green-600' : 'text-yellow-600'
                }`}>
                  {stat.role === 'admin' ? 'Administradores' : 
                   stat.role === 'technician' ? 'Técnicos' : 'Clientes'}
                </p>
                <p className="text-2xl font-bold">{stat.count}</p>
              </div>
            ))}
          </div>
          
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead className="bg-gray-100">
                <tr>
                  <th className="py-2 px-4 text-left">Rol</th>
                  <th className="py-2 px-4 text-left">Cantidad</th>
                  <th className="py-2 px-4 text-left">Primer Registro</th>
                  <th className="py-2 px-4 text-left">Último Registro</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {userReport.userStats.map((stat) => (
                  <tr key={stat.role}>
                    <td className="py-2 px-4">
                      <span className={`px-2 py-1 rounded-full text-xs ${
                        stat.role === 'admin' ? 'bg-purple-100 text-purple-800' : 
                        stat.role === 'technician' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {stat.role === 'admin' ? 'Administrador' : 
                         stat.role === 'technician' ? 'Técnico' : 'Cliente'}
                      </span>
                    </td>
                    <td className="py-2 px-4">{stat.count}</td>
                    <td className="py-2 px-4">{stat.first_registered}</td>
                    <td className="py-2 px-4">{stat.last_registered}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  const renderServiceReport = () => {
    if (loadingServiceReport) {
      return (
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      );
    }

    return (
      <div className="space-y-6">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-4">Filtrar por Fecha</h3>
          
          <div className="flex flex-col md:flex-row gap-4 mb-4">
            <div className="flex-1">
              <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">
                Fecha de Inicio
              </label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={dateRange.startDate}
                onChange={handleDateRangeChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="flex-1">
              <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">
                Fecha Final
              </label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={dateRange.endDate}
                onChange={handleDateRangeChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="flex items-end">
              <button
                onClick={loadServiceReport}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Generar Reporte
              </button>
            </div>
          </div>
        </div>

        {serviceReport && (
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4">
              Estadísticas de Servicios
              <span className="text-sm font-normal text-gray-500 ml-2">
                {serviceReport.dateRange.start} - {serviceReport.dateRange.end}
              </span>
            </h3>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <div className="bg-blue-50 p-4 rounded-lg">
                <p className="text-sm text-blue-600 mb-1">Total de Solicitudes</p>
                <p className="text-2xl font-bold">{serviceReport.totalRequests}</p>
              </div>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h4 className="font-medium mb-2">Por Estado</h4>
                <div className="overflow-x-auto">
                  <table className="min-w-full">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="py-2 px-4 text-left">Estado</th>
                        <th className="py-2 px-4 text-left">Cantidad</th>
                        <th className="py-2 px-4 text-left">Días Promedio</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {serviceReport.statusStats.map((stat) => (
                        <tr key={stat.status}>
                          <td className="py-2 px-4">
                            <span className={`px-2 py-1 rounded-full text-xs ${
                              stat.status === 'pending' ? 'bg-yellow-100 text-yellow-800' : 
                              stat.status === 'accepted' ? 'bg-blue-100 text-blue-800' : 
                              stat.status === 'completed' ? 'bg-green-100 text-green-800' : 
                              'bg-red-100 text-red-800'
                            }`}>
                              {stat.status === 'pending' ? 'Pendiente' : 
                               stat.status === 'accepted' ? 'Aceptada' : 
                               stat.status === 'completed' ? 'Completada' : 
                               'Cancelada'}
                            </span>
                          </td>
                          <td className="py-2 px-4">{stat.count}</td>
                          <td className="py-2 px-4">{stat.avg_days_to_complete ? parseFloat(stat.avg_days_to_complete).toFixed(1) : '-'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              
              <div>
                <h4 className="font-medium mb-2">Categorías Más Solicitadas</h4>
                {serviceReport.topCategories.length > 0 ? (
                  <div className="overflow-x-auto">
                    <table className="min-w-full">
                      <thead className="bg-gray-100">
                        <tr>
                          <th className="py-2 px-4 text-left">Categoría</th>
                          <th className="py-2 px-4 text-left">Solicitudes</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {serviceReport.topCategories.map((category, index) => (
                          <tr key={index}>
                            <td className="py-2 px-4">{category.category_name}</td>
                            <td className="py-2 px-4">{category.request_count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-gray-500">No hay datos de categorías disponibles.</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderRevenueReport = () => {
    if (loadingRevenueReport) {
      return (
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      );
    }

    return (
      <div className="space-y-6">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-4">Filtrar por Año y Mes</h3>
          
          <div className="flex flex-col md:flex-row gap-4 mb-4">
            <div className="flex-1">
              <label htmlFor="year" className="block text-sm font-medium text-gray-700 mb-1">
                Año
              </label>
              <input
                type="number"
                id="year"
                name="year"
                min="2000"
                max="2100"
                value={yearMonth.year}
                onChange={handleYearMonthChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="flex-1">
              <label htmlFor="month" className="block text-sm font-medium text-gray-700 mb-1">
                Mes (1-12, opcional)
              </label>
              <input
                type="number"
                id="month"
                name="month"
                min="1"
                max="12"
                value={yearMonth.month}
                onChange={handleYearMonthChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="flex items-end">
              <button
                onClick={loadRevenueReport}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Generar Reporte
              </button>
            </div>
          </div>
        </div>

        {revenueReport && (
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4">
              Estadísticas de Ingresos
              <span className="text-sm font-normal text-gray-500 ml-2">
                {yearMonth.month ? `${yearMonth.month}/${yearMonth.year}` : `${yearMonth.year}`}
              </span>
            </h3>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <div className="bg-green-50 p-4 rounded-lg">
                <p className="text-sm text-green-600 mb-1">Ingresos Estimados</p>
                <p className="text-2xl font-bold">${revenueReport.totalRevenue.toFixed(2)}</p>
              </div>
              <div className="bg-blue-50 p-4 rounded-lg">
                <p className="text-sm text-blue-600 mb-1">Total de Servicios</p>
                <p className="text-2xl font-bold">{revenueReport.totalServices}</p>
              </div>
            </div>
            
            <h4 className="font-medium mb-2">Ingresos por Categoría</h4>
            {revenueReport.revenueStats.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="py-2 px-4 text-left">Categoría</th>
                      <th className="py-2 px-4 text-left">Servicios</th>
                      <th className="py-2 px-4 text-left">Ingresos Estimados</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {revenueReport.revenueStats.map((stat, index) => (
                      <tr key={index}>
                        <td className="py-2 px-4">{stat.category_name}</td>
                        <td className="py-2 px-4">{stat.services_count}</td>
                        <td className="py-2 px-4">${parseFloat(stat.estimated_revenue).toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="text-gray-500">No hay datos de ingresos disponibles para este período.</p>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-6">Reportes de Gestión</h2>
      
      <div className="mb-6">
        <div className="flex flex-wrap border-b border-gray-200">
          <button
            className={`px-4 py-2 font-medium text-sm ${
              activeReport === 'users'
                ? 'border-b-2 border-blue-500 text-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => handleTabChange('users')}
          >
            <Users className="h-4 w-4 inline mr-1" />
            Usuarios
          </button>
          <button
            className={`px-4 py-2 font-medium text-sm ${
              activeReport === 'services'
                ? 'border-b-2 border-blue-500 text-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => handleTabChange('services')}
          >
            <Briefcase className="h-4 w-4 inline mr-1" />
            Servicios
          </button>
          <button
            className={`px-4 py-2 font-medium text-sm ${
              activeReport === 'revenue'
                ? 'border-b-2 border-blue-500 text-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => handleTabChange('revenue')}
          >
            <DollarSign className="h-4 w-4 inline mr-1" />
            Ingresos
          </button>
        </div>
      </div>
      
      <div>
        {activeReport === 'users' && renderUserReport()}
        {activeReport === 'services' && renderServiceReport()}
        {activeReport === 'revenue' && renderRevenueReport()}
      </div>
    </div>
  );
};

export default Reports; 