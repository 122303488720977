import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Briefcase, DollarSign, Clock, MapPin } from 'react-feather';
import { technicianService } from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-hot-toast';

const BecomeTechnician = () => {
  const navigate = useNavigate();
  const { user, login } = useAuth(); // Need login to update user context after upgrade
  const [formData, setFormData] = useState({
    category_id: '',
    bio: '',
    experience_years: '',
    hourly_rate: '',
    address: '',
    city: '',
    profile_image: null
  });
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    if (!user || user.role !== 'customer') {
      toast.error('Acceso no autorizado.');
      navigate('/');
      return;
    }

    const fetchCategories = async () => {
      try {
        const data = await technicianService.getCategories();
        if (data.categories) {
          setCategories(data.categories);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
        toast.error('Error al cargar categorías.');
      }
    };

    fetchCategories();
  }, [user, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({ ...prev, profile_image: file }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const submitData = new FormData();
      Object.keys(formData).forEach(key => {
        if (formData[key] !== null) { // Don't append null values
          submitData.append(key, formData[key]);
        }
      });

      // Call the new API endpoint (to be created)
      const response = await technicianService.upgradeToTechnician(submitData);
      
      toast.success('¡Felicidades! Tu cuenta ha sido actualizada a Técnico.');
      
      // Update user context with new role and technician data
      if (response.user) {
        login(response.user); // Use login to update context and localStorage
      }
      
      navigate('/my-profile'); // Redirect to the new technician profile
      
    } catch (error) {
      console.error('Upgrade error:', error);
      toast.error(error.message || 'Error al actualizar la cuenta.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="bg-blue-600 text-white shadow-md">
        <div className="container mx-auto px-4 py-3 flex items-center">
          <button 
            onClick={() => navigate('/my-account')} 
            className="flex items-center text-white hover:underline"
          >
            <ArrowLeft className="mr-1 h-4 w-4" />
            Volver a Mi Cuenta
          </button>
        </div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-4 py-8">
        <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6 md:p-8">
          <div className="text-center mb-8">
            <Briefcase className="h-12 w-12 text-purple-600 mx-auto mb-3" />
            <h1 className="text-2xl font-bold">Convertirme en Técnico</h1>
            <p className="text-gray-600 mt-2">Completa tu información profesional.</p>
          </div>
          
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            
              {/* Category */}
              <div>
                <label htmlFor="category_id" className="block text-gray-700 text-sm font-medium mb-2">
                  Categoría Principal <span className="text-red-500">*</span>
                </label>
                <select
                  id="category_id"
                  name="category_id"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                  value={formData.category_id}
                  onChange={handleChange}
                  required
                >
                  <option value="">Selecciona una categoría</option>
                  {categories.map(category => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              
              {/* Experience */}
              <div>
                <label htmlFor="experience_years" className="block text-gray-700 text-sm font-medium mb-2">
                  Años de Experiencia <span className="text-red-500">*</span>
                </label>
                <input
                  id="experience_years"
                  name="experience_years"
                  type="number"
                  min="0" max="50"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                  value={formData.experience_years}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* Hourly Rate */}
              <div>
                <label htmlFor="hourly_rate" className="block text-gray-700 text-sm font-medium mb-2">
                  Tarifa por Hora (RD$) <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <DollarSign className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      id="hourly_rate"
                      name="hourly_rate"
                      type="number"
                      min="0"
                      className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                      value={formData.hourly_rate}
                      onChange={handleChange}
                      required
                    />
                </div>
              </div>

              {/* Profile Image */}
              <div>
                <label htmlFor="profile_image" className="block text-gray-700 text-sm font-medium mb-2">
                  Foto de Perfil (Opcional)
                </label>
                <input
                  id="profile_image"
                  name="profile_image"
                  type="file"
                  accept="image/*"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                  onChange={handleImageChange}
                />
                {imagePreview && (
                  <div className="mt-2">
                    <img 
                      src={imagePreview} 
                      alt="Vista previa" 
                      className="w-24 h-24 object-cover rounded-full border border-gray-300" 
                    />
                  </div>
                )}
              </div>

              {/* Bio */}              
              <div className="md:col-span-2">
                <label htmlFor="bio" className="block text-gray-700 text-sm font-medium mb-2">
                  Biografía / Descripción <span className="text-red-500">*</span>
                </label>
                <textarea
                  id="bio"
                  name="bio"
                  rows="4"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                  placeholder="Describe tus habilidades y servicios..."
                  value={formData.bio}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>

              {/* City */}
              <div>
                <label htmlFor="city" className="block text-gray-700 text-sm font-medium mb-2">
                  Ciudad <span className="text-red-500">*</span>
                </label>
                <select
                  id="city"
                  name="city"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                  value={formData.city}
                  onChange={handleChange}
                  required
                >
                  <option value="">Selecciona una ciudad</option>
                  <option value="Santiago">Santiago</option>
                  <option value="Santo Domingo">Santo Domingo</option>
                </select>
              </div>

              {/* Address */}
              <div>
                <label htmlFor="address" className="block text-gray-700 text-sm font-medium mb-2">
                  Dirección (Sector/Barrio)
                </label>
                <input
                  id="address"
                  name="address"
                  type="text"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                  placeholder="Ej: Los Jardines Metropolitanos"
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>

              {/* Submit Button */}
              <div className="md:col-span-2 mt-6">
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full bg-purple-600 hover:bg-purple-700 text-white font-medium py-3 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 disabled:opacity-50"
                >
                  {loading ? (
                    <span className="flex items-center justify-center">
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Actualizando cuenta...
                    </span>
                  ) : 'Completar Registro de Técnico'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
};

export default BecomeTechnician; 