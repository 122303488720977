import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-hot-toast';

const MyAccount = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      toast.error('Debe iniciar sesión para ver su cuenta');
      navigate('/login');
      return;
    }
    setLoading(false);
  }, [user, navigate]);

  const handleLogout = async () => {
    try {
      await logout();
      toast.success('Sesión cerrada exitosamente');
      navigate('/');
    } catch (error) {
      toast.error('Error al cerrar sesión');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-4xl mx-auto px-4">
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="bg-blue-600 px-6 py-4">
            <h1 className="text-2xl font-bold text-white">Mi Cuenta</h1>
          </div>
          
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-4">
                <h2 className="text-xl font-semibold text-gray-800">Información Personal</h2>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-gray-600">
                    <span className="font-medium">Nombre:</span> {user.name}
                  </p>
                  <p className="text-gray-600">
                    <span className="font-medium">Email:</span> {user.email}
                  </p>
                  <p className="text-gray-600">
                    <span className="font-medium">Teléfono:</span> {user.phone || 'No especificado'}
                  </p>
                  <p className="text-gray-600">
                    <span className="font-medium">Tipo de cuenta:</span>{' '}
                    {user.role === 'admin' 
                      ? 'Administrador' 
                      : user.role === 'technician' 
                        ? 'Técnico' 
                        : 'Cliente'}
                  </p>
                </div>
              </div>

              <div className="space-y-4">
                <h2 className="text-xl font-semibold text-gray-800">Acciones de Cuenta</h2>
                <div className="space-y-3">
                  {user.role === 'technician' && (
                    <button
                      onClick={() => navigate('/my-profile')}
                      className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
                    >
                      Ver Mi Perfil de Técnico
                    </button>
                  )}
                  {user.role === 'admin' && (
                    <button
                      onClick={() => navigate('/admin')}
                      className="w-full bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 transition-colors"
                    >
                      Panel de Administración
                    </button>
                  )}
                  <button
                    onClick={() => navigate(user.role === 'technician' ? '/my-profile/edit' : '/my-account/edit')}
                    className="w-full bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors"
                  >
                    Editar Perfil
                  </button>
                  <button
                    onClick={() => navigate('/my-requests')}
                    className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
                  >
                    Mis Solicitudes de Servicio
                  </button>
                  {user.role === 'customer' && (
                    <button
                      onClick={() => navigate('/become-technician')}
                      className="w-full bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 transition-colors"
                    >
                      Convertirme en Técnico
                    </button>
                  )}
                  <button
                    onClick={handleLogout}
                    className="w-full bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors"
                  >
                    Cerrar Sesión
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount; 