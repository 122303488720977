import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, Star } from 'react-feather';
import { useAuth } from '../contexts/AuthContext';
import { serviceRequestService } from '../services/api';
import { technicianService } from '../services/api';
import { toast } from 'react-hot-toast';

const ReviewForm = () => {
  const { technicianId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  
  const [technician, setTechnician] = useState(null);
  const [loading, setLoading] = useState(true);
  const [canReview, setCanReview] = useState(false);
  const [review, setReview] = useState({
    rating: 5,
    comment: '',
    service_date: new Date().toISOString().split('T')[0]
  });

  useEffect(() => {
    if (!user) {
      toast.error('Debe iniciar sesión para escribir una reseña');
      navigate('/login');
      return;
    }
    
    if (!technicianId) {
      toast.error('ID de técnico no válido');
      navigate(-1);
      return;
    }
    
    fetchTechnicianAndCheckEligibility();
  }, [user, technicianId, navigate]);

  const fetchTechnicianAndCheckEligibility = async () => {
    try {
      setLoading(true);
      
      // Check if user is eligible to review this technician
      const eligibilityResponse = await serviceRequestService.checkReviewEligibility(technicianId);
      setCanReview(eligibilityResponse.canReview);
      
      if (!eligibilityResponse.canReview) {
        toast.error('Debes recibir un servicio de este técnico antes de escribir una reseña');
        // Wait a bit before navigating back
        setTimeout(() => navigate(-1), 2000);
        return;
      }
      
      // Get technician details
      const technicianResponse = await technicianService.getTechnicianById(technicianId);
      setTechnician(technicianResponse.technician);
    } catch (error) {
      console.error('Error in review initialization:', error);
      toast.error(error.message || 'Error al cargar los datos');
    } finally {
      setLoading(false);
    }
  };

  const handleRatingChange = (newRating) => {
    setReview({ ...review, rating: newRating });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      if (!canReview) {
        toast.error('No puedes escribir una reseña para este técnico');
        return;
      }
      
      const reviewData = {
        technician_id: parseInt(technicianId),
        rating: review.rating,
        comment: review.comment,
        service_date: review.service_date
      };
      
      // Submit review using the API service
      const response = await fetch('/api/reviews', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(reviewData)
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error al enviar la reseña');
      }
      
      toast.success('Reseña enviada exitosamente');
      navigate('/my-requests');
    } catch (error) {
      console.error('Error submitting review:', error);
      toast.error(error.message || 'Error al enviar la reseña');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!canReview || !technician) {
    return (
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-4xl mx-auto px-4">
          <div className="bg-white rounded-lg shadow-md p-6 text-center">
            <p className="text-lg text-gray-700 mb-4">
              No puedes escribir una reseña para este técnico en este momento.
            </p>
            <button
              onClick={() => navigate(-1)}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Volver
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-4xl mx-auto px-4">
        <div className="mb-6">
          <button 
            onClick={() => navigate(-1)} 
            className="flex items-center text-blue-600 hover:underline"
          >
            <ArrowLeft className="mr-1 h-4 w-4" />
            Volver
          </button>
        </div>

        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <div className="bg-blue-600 px-6 py-4">
            <h1 className="text-2xl font-bold text-white">Escribir Reseña</h1>
          </div>
          
          <div className="p-6">
            <div className="mb-6 flex items-center">
              <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-200 mr-4">
                {technician.profile_image ? (
                  <img 
                    src={`/uploads/${technician.profile_image}`} 
                    alt={technician.name} 
                    className="w-full h-full object-cover"
                    onError={(e) => {
                      e.target.onerror = null; 
                      e.target.src = 'https://via.placeholder.com/128?text=Sin+Imagen';
                    }}
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center text-2xl text-gray-400">
                    {technician.name ? technician.name.charAt(0).toUpperCase() : 'T'}
                  </div>
                )}
              </div>
              
              <div>
                <h2 className="text-xl font-semibold">{technician.name}</h2>
                <p className="text-gray-600">{technician.category_name}</p>
              </div>
            </div>
            
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-medium mb-2">
                  Puntuación <span className="text-red-500">*</span>
                </label>
                <div className="flex space-x-2">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <button
                      key={star}
                      type="button"
                      onClick={() => handleRatingChange(star)}
                      className="focus:outline-none"
                    >
                      <Star 
                        className={`h-8 w-8 ${
                          star <= review.rating 
                            ? 'text-yellow-400 fill-current' 
                            : 'text-gray-300'
                        }`}
                      />
                    </button>
                  ))}
                </div>
              </div>
              
              <div className="mb-6">
                <label htmlFor="comment" className="block text-gray-700 text-sm font-medium mb-2">
                  Comentario <span className="text-red-500">*</span>
                </label>
                <textarea
                  id="comment"
                  rows="4"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Describe tu experiencia con este técnico"
                  required
                  minLength={10}
                  value={review.comment}
                  onChange={(e) => setReview({...review, comment: e.target.value})}
                ></textarea>
              </div>
              
              <div className="mb-6">
                <label htmlFor="service_date" className="block text-gray-700 text-sm font-medium mb-2">
                  Fecha del Servicio <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  id="service_date"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  required
                  max={new Date().toISOString().split('T')[0]}
                  value={review.service_date}
                  onChange={(e) => setReview({...review, service_date: e.target.value})}
                />
              </div>
              
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                  Enviar Reseña
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewForm; 