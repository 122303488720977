import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Edit, Star, Mail, Phone, MapPin, Calendar, MessageSquare } from 'react-feather';
import { useAuth } from '../contexts/AuthContext';
import { technicianService } from '../services/api';
import { serviceRequestService } from '../services/api';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';

const TechnicianProfile = ({ isOwnProfile }) => {
  const [loading, setLoading] = useState(true);
  const [technician, setTechnician] = useState(null);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [requestData, setRequestData] = useState({
    service_description: '',
    request_date: new Date().toISOString().split('T')[0],
    service_location: ''
  });
  const [canReview, setCanReview] = useState(false);

  useEffect(() => {
    const fetchTechnicianData = async () => {
      setLoading(true);
      setError(null);
      try {
        console.log('Auth user:', user);
        console.log('isOwnProfile prop:', isOwnProfile);
        console.log('URL param id:', id);

        // Two possible scenarios:
        // 1. Viewing own profile (isOwnProfile=true)
        // 2. Viewing another technician's profile (using id from params)
        
        let technicianData;
        
        if (isOwnProfile) {
          console.log('Fetching own profile...');
          if (!user) {
            console.error('No authenticated user found while trying to view own profile');
            setError('Debes iniciar sesión para ver tu perfil');
            setLoading(false);
            return;
          }
          
          if (user.role !== 'technician') {
            console.error('User is not a technician');
            setError('Tu cuenta no es una cuenta de técnico');
            setLoading(false);
            return;
          }
          
          const response = await technicianService.getOwnProfile();
          console.log('Own profile response:', response);
          technicianData = response.technician;
        } else {
          // Viewing another technician's profile
          if (!id) {
            console.error('No technician ID provided in URL params');
            setError('ID de técnico no encontrado');
            setLoading(false);
            return;
          }
          
          console.log(`Fetching technician with ID: ${id}`);
          const response = await technicianService.getTechnicianById(id);
          console.log('Technician by ID response:', response);
          technicianData = response.technician;
        }
        
        if (!technicianData) {
          console.error('No technician data found in response');
          setError('No se pudo encontrar la información del técnico');
          setLoading(false);
          return;
        }
        
        console.log('Setting technician data:', technicianData);
        setTechnician(technicianData);
      } catch (err) {
        console.error('Error fetching technician data:', err);
        setError(err.message || 'Error al cargar el perfil del técnico');
      } finally {
        setLoading(false);
      }
    };

    fetchTechnicianData();
  }, [id, isOwnProfile, user]);

  useEffect(() => {
    if (technician && user && user.role === 'customer' && !isOwnProfile) {
      checkReviewEligibility();
    }
  }, [technician, user, isOwnProfile]);

  const handleRequestService = async (e) => {
    e.preventDefault();
    
    try {
      console.log('Sending service request data:', {
        technician_id: technician.id,
        ...requestData
      });
      
      await serviceRequestService.createServiceRequest({
        technician_id: technician.id,
        ...requestData
      });
      
      toast.success('Solicitud de servicio enviada exitosamente');
      setShowRequestModal(false);
      
      // Reset form
      setRequestData({
        service_description: '',
        request_date: new Date().toISOString().split('T')[0],
        service_location: ''
      });
    } catch (error) {
      console.error('Error details:', error);
      toast.error(error.message || 'Error al enviar la solicitud');
    }
  };

  const checkReviewEligibility = async () => {
    if (!user || user.role !== 'customer' || isOwnProfile) return;
    
    try {
      const response = await serviceRequestService.checkReviewEligibility(technician.id);
      setCanReview(response.canReview);
    } catch (error) {
      console.error('Error checking review eligibility:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="spinner"></div>
        <p className="ml-2">Cargando perfil...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-4">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded max-w-lg w-full mb-4">
          <p>{error}</p>
        </div>
        <button 
          onClick={() => navigate('/')} 
          className="flex items-center text-blue-600 hover:underline"
        >
          <ArrowLeft className="mr-1 h-4 w-4" />
          Volver a la página principal
        </button>
      </div>
    );
  }

  if (!technician) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-4">
        <div className="bg-yellow-50 border border-yellow-200 text-yellow-700 px-4 py-3 rounded max-w-lg w-full mb-4">
          <p>No se encontró información del técnico.</p>
        </div>
        <button 
          onClick={() => navigate('/')} 
          className="flex items-center text-blue-600 hover:underline"
        >
          <ArrowLeft className="mr-1 h-4 w-4" />
          Volver a la página principal
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="bg-blue-600 text-white shadow-md">
        <div className="container mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className="text-xl font-bold">TechConnect Santiago</h1>
          
          {/* Navigation */}
          <nav className="flex items-center space-x-6">
            <button 
              onClick={() => navigate('/')} 
              className="flex items-center text-white hover:underline"
            >
              <ArrowLeft className="mr-1 h-4 w-4" />
              Volver
            </button>
          </nav>
        </div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          {/* Profile Header */}
          <div className="relative bg-blue-600 h-56 flex justify-end px-4 pt-4">
            {isOwnProfile && (
              <button 
                onClick={() => navigate('/my-profile/edit')}
                className="bg-white text-blue-600 hover:bg-blue-50 p-2 rounded-full shadow-md"
                aria-label="Edit profile"
              >
                <Edit className="h-5 w-5" />
              </button>
            )}
          </div>
          
          {/* Profile Content */}
          <div className="px-6 pb-6">
            <div className="flex flex-col md:flex-row relative">
              {/* Avatar Section - Repositioned */}
              <div className="flex flex-col items-center absolute top-0 left-6 -mt-16 md:static md:mr-6">
                <div className="w-32 h-32 rounded-full overflow-hidden border-4 border-white bg-gray-200 flex items-center justify-center shadow-md mb-4">
                  {technician.profile_image ? (
                    <img 
                      src={`/uploads/${technician.profile_image}`} 
                      alt={`${technician.name || 'Técnico'}`} 
                      className="w-full h-full object-cover"
                      onError={(e) => {
                        e.target.onerror = null; 
                        e.target.src = 'https://via.placeholder.com/128?text=Sin+Imagen';
                      }}
                    />
                  ) : (
                    <span className="text-5xl text-gray-400">{technician.name ? technician.name.charAt(0).toUpperCase() : 'T'}</span>
                  )}
                </div>
                
                <div className="flex items-center mb-2">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <Star 
                      key={star} 
                      className={`h-5 w-5 ${
                        star <= Math.round(technician.average_rating || 0) 
                          ? 'text-yellow-400 fill-current' 
                          : 'text-gray-300'
                      }`}
                    />
                  ))}
                </div>
                <div className="text-sm text-gray-600">
                  {technician.average_rating && typeof technician.average_rating === 'number' 
                    ? technician.average_rating.toFixed(1) 
                    : '0.0'} ({technician.review_count || 0} reseñas)
                </div>
              </div>
              
              {/* Info Section - With added padding-top for mobile view */}
              <div className="flex-1 mt-24 md:mt-0 pt-4 md:pt-0">
                <h1 className="text-2xl font-bold text-gray-800">{technician.name || 'Nombre no disponible'}</h1>
                <p className="text-blue-600 font-medium">{technician.category_name || 'Categoría no especificada'}</p>
                
                <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="flex items-start">
                    <MapPin className="h-5 w-5 text-gray-400 mt-0.5 mr-2 flex-shrink-0" />
                    <div>
                      <h3 className="text-sm font-medium text-gray-800">Ubicación</h3>
                      <p className="text-gray-600">{technician.city || 'No especificada'}</p>
                      <p className="text-gray-600">{technician.address || 'Dirección no disponible'}</p>
                    </div>
                  </div>
                  
                  <div className="flex items-start">
                    <Mail className="h-5 w-5 text-gray-400 mt-0.5 mr-2 flex-shrink-0" />
                    <div>
                      <h3 className="text-sm font-medium text-gray-800">Email</h3>
                      <p className="text-gray-600">{technician.email || 'No disponible'}</p>
                    </div>
                  </div>
                  
                  <div className="flex items-start">
                    <Phone className="h-5 w-5 text-gray-400 mt-0.5 mr-2 flex-shrink-0" />
                    <div>
                      <h3 className="text-sm font-medium text-gray-800">Teléfono</h3>
                      <p className="text-gray-600">{technician.phone || 'No disponible'}</p>
                    </div>
                  </div>
                  
                  <div className="flex items-start">
                    <Star className="h-5 w-5 text-gray-400 mt-0.5 mr-2 flex-shrink-0" />
                    <div>
                      <h3 className="text-sm font-medium text-gray-800">Experiencia</h3>
                      <p className="text-gray-600">{technician.experience_years || 0} años</p>
                    </div>
                  </div>
                </div>
                
                <div className="mt-6">
                  <h2 className="text-lg font-semibold text-gray-800 mb-2">Acerca de</h2>
                  <p className="text-gray-600 whitespace-pre-line">
                    {technician.bio || 'No hay información disponible sobre este técnico.'}
                  </p>
                </div>
                
                <div className="mt-6">
                  <h2 className="text-lg font-semibold text-gray-800 mb-2">Habilidades</h2>
                  {technician.skills && technician.skills.length > 0 ? (
                    <div className="flex flex-wrap gap-2">
                      {technician.skills.map((skill, index) => (
                        <span 
                          key={index} 
                          className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm"
                        >
                          {skill.skill_name}
                        </span>
                      ))}
                    </div>
                  ) : (
                    <p className="text-gray-600">No se han agregado habilidades.</p>
                  )}
                </div>
                
                <div className="mt-6">
                  <h2 className="text-lg font-semibold text-gray-800 mb-2">Tarifa</h2>
                  <p className="text-2xl font-bold text-green-600">
                    ${technician.hourly_rate || 0} <span className="text-gray-600 text-sm font-normal">por hora</span>
                  </p>
                </div>

                {/* Show request button if user is logged in AND not viewing their own profile */}
                {user && !isOwnProfile && (
                  <div className="mt-8 flex flex-col sm:flex-row gap-3">
                    <button
                      onClick={() => setShowRequestModal(true)}
                      className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition flex items-center justify-center"
                    >
                      <Calendar className="h-5 w-5 mr-2" />
                      Solicitar Servicio
                    </button>
                    
                    {/* Review button logic - Keep this specific to customers */}
                    {user.role === 'customer' && (
                      <>
                      {canReview ? (
                        <Link 
                          to={`/reviews/create/${technician.id}`}
                          className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition flex items-center justify-center"
                        >
                          <MessageSquare className="h-5 w-5 mr-2" />
                          Escribir Reseña
                        </Link>
                      ) : (
                        <button
                          disabled
                          title="Debes recibir un servicio primero"
                          className="px-4 py-2 bg-gray-400 text-white rounded cursor-not-allowed flex items-center justify-center"
                        >
                          <MessageSquare className="h-5 w-5 mr-2" />
                          Escribir Reseña
                        </button>
                      )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            
            {/* Reviews Section */}
            <div className="mt-8 border-t pt-6">
              <h2 className="text-lg font-semibold text-gray-800 mb-4">Reseñas ({technician.review_count || 0})</h2>
              
              {technician.reviews && technician.reviews.length > 0 ? (
                <div className="space-y-4">
                  {technician.reviews.map((review) => (
                    <div key={review.id} className="bg-gray-50 p-4 rounded-lg">
                      <div className="flex justify-between items-start">
                        <div>
                          <h3 className="font-medium">{review.reviewer_name || 'Cliente'}</h3>
                          <div className="flex items-center mt-1">
                            {[1, 2, 3, 4, 5].map((star) => (
                              <Star 
                                key={star} 
                                className={`h-4 w-4 ${
                                  star <= review.rating 
                                    ? 'text-yellow-400 fill-current' 
                                    : 'text-gray-300'
                                }`}
                              />
                            ))}
                            <span className="ml-2 text-sm text-gray-600">
                              {new Date(review.created_at).toLocaleDateString()}
                            </span>
                          </div>
                        </div>
                      </div>
                      <p className="mt-2 text-gray-600">{review.comment}</p>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-600">Aún no hay reseñas para este técnico.</p>
              )}
            </div>
          </div>
        </div>
      </main>
      
      {/* Footer */}
      <footer className="bg-gray-800 text-gray-300 py-8 px-4 mt-auto">
        <div className="container mx-auto">
          <div className="text-center">
            <p>&copy; {new Date().getFullYear()} TechConnect Santiago. Todos los derechos reservados.</p>
          </div>
        </div>
      </footer>

      {/* Service Request Modal */}
      {showRequestModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-4">Solicitar Servicio de {technician.name}</h3>
              
              <form onSubmit={handleRequestService}>
                <div className="mb-4">
                  <label htmlFor="service_description" className="block text-gray-700 text-sm font-medium mb-2">
                    Descripción del Servicio <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    id="service_description"
                    rows="4"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Describe el servicio que necesitas"
                    required
                    value={requestData.service_description}
                    onChange={(e) => setRequestData({...requestData, service_description: e.target.value})}
                  ></textarea>
                </div>
                
                <div className="mb-4">
                  <label htmlFor="request_date" className="block text-gray-700 text-sm font-medium mb-2">
                    Fecha Deseada <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="date"
                    id="request_date"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    required
                    min={new Date().toISOString().split('T')[0]}
                    value={requestData.request_date}
                    onChange={(e) => setRequestData({...requestData, request_date: e.target.value})}
                  />
                </div>
                
                <div className="mb-6">
                  <label htmlFor="service_location" className="block text-gray-700 text-sm font-medium mb-2">
                    Ubicación del Servicio <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="service_location"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Dirección completa donde se realizará el servicio"
                    required
                    value={requestData.service_location}
                    onChange={(e) => setRequestData({...requestData, service_location: e.target.value})}
                  />
                </div>
                
                <div className="flex justify-end gap-3">
                  <button
                    type="button"
                    className="px-4 py-2 text-gray-700 border border-gray-300 rounded hover:bg-gray-100"
                    onClick={() => setShowRequestModal(false)}
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                  >
                    Enviar Solicitud
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TechnicianProfile; 
