import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Landing from './pages/Landing';
import Search from './pages/Search';
import RegisterTechnician from './pages/RegisterTechnician';
import BecomeTechnician from './pages/BecomeTechnician';
import EditTechnicianProfile from './components/EditTechnicianProfile';
import TechnicianProfile from './components/TechnicianProfile';
import HowItWorks from './pages/HowItWorks';
import MyAccount from './components/MyAccount';
import AuthProvider from './contexts/AuthContext';
import EditCustomerProfile from './components/EditCustomerProfile';
import MyServiceRequests from './components/MyServiceRequests';
import ReviewForm from './components/ReviewForm';
import AdminDashboard from './components/admin/AdminDashboard';
import UserManagement from './components/admin/UserManagement';
import CategoryManagement from './components/admin/CategoryManagement';
import TechnicianManagement from './components/admin/TechnicianManagement';
import Reports from './components/admin/Reports';
import './App.css';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="min-h-screen bg-gray-50">
          <main>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/search" element={<Search />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/register-technician" element={<RegisterTechnician />} />
              <Route path="/como-funciona" element={<HowItWorks />} />
              <Route path="/technicians/profile/edit" element={<EditTechnicianProfile />} />
              <Route path="/my-profile/edit" element={<EditTechnicianProfile />} />
              <Route path="/my-profile" element={<TechnicianProfile isOwnProfile={true} />} />
              <Route path="/technicians/:id" element={<TechnicianProfile />} />
              <Route path="/my-account" element={<MyAccount />} />
              <Route path="/my-account/edit" element={<EditCustomerProfile />} />
              <Route path="/become-technician" element={<BecomeTechnician />} />
              <Route path="/my-requests" element={<MyServiceRequests />} />
              <Route path="/reviews/create/:technicianId" element={<ReviewForm />} />
              
              {/* Admin Routes */}
              <Route path="/admin" element={<AdminDashboard />}>
                <Route index element={<Reports />} />
                <Route path="users" element={<UserManagement />} />
                <Route path="categories" element={<CategoryManagement />} />
                <Route path="technicians" element={<TechnicianManagement />} />
                <Route path="reports" element={<Reports />} />
              </Route>
            </Routes>
          </main>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;