import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, MapPin, Star, Settings, User, Menu, X } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';

const Landing = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [category, setCategory] = useState('all');
  const [technicians, setTechnicians] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);
  const [lastRefreshed, setLastRefreshed] = useState(new Date().toLocaleTimeString());

  // Function to refresh technicians data
  const refreshTechnicians = () => {
    setRefreshCount(prev => prev + 1);
    setLastRefreshed(new Date().toLocaleTimeString());
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Fetch categories - Using the correct endpoint
        const categoriesResponse = await axios.get('/api/technicians/categories');
        
        // Add the "All" category option at the beginning
        if (categoriesResponse.data && Array.isArray(categoriesResponse.data.categories)) {
          const allCategories = [
            { id: 'all', name: 'Todos', icon: <Settings className="w-5 h-5" /> },
            ...categoriesResponse.data.categories.map(cat => ({
              id: cat.id.toString(),
              name: cat.name,
              icon: <Settings className="w-5 h-5" />
            }))
          ];
          setCategories(allCategories);
        } else {
          // Fallback to hardcoded categories if API doesn't return expected format
          setCategories([
            { id: 'all', name: 'Todos', icon: <Settings className="w-5 h-5" /> },
            { id: '1', name: 'Electricidad', icon: <Settings className="w-5 h-5" /> },
            { id: '2', name: 'Plomería', icon: <Settings className="w-5 h-5" /> },
            { id: '3', name: 'Carpintería', icon: <Settings className="w-5 h-5" /> },
            { id: '4', name: 'Climatización', icon: <Settings className="w-5 h-5" /> },
            { id: '5', name: 'Electrónica', icon: <Settings className="w-5 h-5" /> },
            { id: '6', name: 'Informática', icon: <Settings className="w-5 h-5" /> },
          ]);
        }
        
        // Fetch technicians with cache-busting to ensure fresh data
        // Add a timestamp to ensure we're not getting cached data
        const timestamp = new Date().getTime();
        const techniciansResponse = await axios.get(`/api/technicians?t=${timestamp}`);
        
        // Check if response.data is an object with a technicians property
        if (techniciansResponse.data && Array.isArray(techniciansResponse.data.technicians)) {
          setTechnicians(techniciansResponse.data.technicians);
        } else if (Array.isArray(techniciansResponse.data)) {
          // If response.data is directly an array
          setTechnicians(techniciansResponse.data);
        } else {
          // Fallback to empty array if neither of the expected formats
          console.error('Unexpected API response format:', techniciansResponse.data);
          setTechnicians([]);
        }
        
        setError(null);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(`No se pudieron cargar los datos: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    
    // Set up periodic refresh (every 15 seconds)
    const refreshInterval = setInterval(() => {
      refreshTechnicians();
    }, 15000);
    
    return () => clearInterval(refreshInterval);
  }, [refreshCount]);

  const handleSearch = () => {
    navigate('/search');
  };

  const filteredTechnicians = Array.isArray(technicians) ? technicians.filter(tech => {
    const matchesCategory = category === 'all' || String(tech.category_id) === category;
    const matchesSearch = 
      !searchQuery || 
      (tech.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
       tech.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
       tech.city?.toLowerCase().includes(searchQuery.toLowerCase()));
    
    return matchesCategory && matchesSearch;
  }) : [];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="bg-blue-600 text-white shadow-md">
        <div className="container mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className="text-xl font-bold">TechConnect Santiago</h1>
          
          {/* Mobile menu button */}
          <button 
            className="md:hidden" 
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <X /> : <Menu />}
          </button>
          
          {/* Desktop navigation */}
          <nav className="hidden md:flex items-center space-x-6">
            <button onClick={() => navigate('/')} className="text-white hover:underline">Inicio</button>
            <button onClick={() => navigate('/como-funciona')} className="text-white hover:underline">Cómo Funciona</button>
            {isAuthenticated ? (
              <>
                <button onClick={() => navigate('/my-account')} className="text-white hover:underline">Mi Cuenta</button>
                {user?.role === 'technician' && (
                  <button onClick={() => navigate('/my-profile')} className="text-white hover:underline">Mi Perfil</button>
                )}
              </>
            ) : (
              <>
                <button onClick={() => navigate('/register')} className="text-white hover:underline">Registrarse</button>
                <button onClick={() => navigate('/login')} className="text-white hover:underline">Iniciar Sesión</button>
                <button 
                  onClick={() => navigate('/register-technician')}
                  className="bg-white text-blue-600 px-4 py-2 rounded-md font-semibold hover:bg-blue-50"
                >
                  Soy Técnico
                </button>
              </>
            )}
          </nav>
        </div>
        
        {/* Mobile navigation */}
        {isMenuOpen && (
          <div className="md:hidden bg-blue-700 py-4">
            <div className="container mx-auto px-4 flex flex-col space-y-3">
              <button onClick={() => navigate('/')} className="text-left py-2 hover:bg-blue-600 px-3 rounded text-white">Inicio</button>
              <button onClick={() => navigate('/como-funciona')} className="text-left py-2 hover:bg-blue-600 px-3 rounded text-white">Cómo Funciona</button>
              {isAuthenticated ? (
                <>
                  <button onClick={() => navigate('/my-account')} className="text-left py-2 hover:bg-blue-600 px-3 rounded text-white">Mi Cuenta</button>
                  {user?.role === 'technician' && (
                    <button onClick={() => navigate('/my-profile')} className="text-left py-2 hover:bg-blue-600 px-3 rounded text-white">Mi Perfil</button>
                  )}
                </>
              ) : (
                <>
                  <button onClick={() => navigate('/register')} className="text-left py-2 hover:bg-blue-600 px-3 rounded text-white">Registrarse</button>
                  <button onClick={() => navigate('/login')} className="text-left py-2 hover:bg-blue-600 px-3 rounded text-white">Iniciar Sesión</button>
                  <button 
                    onClick={() => navigate('/register-technician')}
                    className="bg-white text-blue-600 px-4 py-2 rounded-md font-semibold text-left"
                  >
                    Soy Técnico
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </header>

      {/* Hero Section */}
      <div className="relative overflow-hidden">
        {/* Background Image */}
        <div 
          className="absolute inset-0 z-0 bg-cover bg-center"
          style={{ 
            backgroundImage: 'url("/images/technicians-collage.png")',
            backgroundBlendMode: 'overlay',
            filter: 'brightness(0.6)'
          }}
        ></div>
        
        {/* Gradient Overlay */}
        <div className="absolute inset-0 z-10 bg-gradient-to-r from-blue-900/70 to-blue-700/70"></div>
        
        {/* Content */}
        <div className="relative z-20 py-20 px-4">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl md:text-5xl font-bold mb-4 text-white drop-shadow-lg">
              Encuentra técnicos profesionales en Santiago
            </h2>
            <p className="text-lg md:text-xl mb-8 max-w-2xl mx-auto text-white drop-shadow">
              Conectamos a personas con los mejores técnicos locales para resolver cualquier problema en tu hogar u oficina.
            </p>
            
            {/* Search bar */}
            <div className="max-w-2xl mx-auto flex">
              <div className="relative flex-grow">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <Search className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  placeholder="¿Qué servicio necesitas?"
                  className="w-full py-3 pl-10 pr-4 text-gray-700 bg-white rounded-l-md focus:outline-none shadow-lg"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <button 
                className="bg-amber-500 hover:bg-amber-600 text-white font-semibold py-3 px-6 rounded-r-md shadow-lg"
                onClick={handleSearch}
              >
                Buscar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Categories Section */}
      <div className="container mx-auto px-4 py-8">
        <h3 className="text-xl font-semibold mb-4">Categorías Populares</h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-4">
          {categories.map((cat) => (
            <button
              key={cat.id}
              className={`p-4 rounded-lg flex flex-col items-center justify-center border transition-colors ${
                category === cat.id 
                  ? 'bg-blue-100 border-blue-500 text-blue-700' 
                  : 'bg-white border-gray-200 hover:bg-gray-50'
              }`}
              onClick={() => setCategory(cat.id)}
            >
              {cat.icon}
              <span className="mt-2 text-sm font-medium">{cat.name}</span>
            </button>
          ))}
        </div>
      </div>

      {/* Featured Technicians */}
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">Técnicos Destacados</h3>
          <button 
            onClick={refreshTechnicians}
            className="text-blue-600 text-sm font-medium hover:text-blue-800 flex items-center"
            disabled={loading}
          >
            {loading ? 'Actualizando...' : 'Actualizar'}
            <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 ml-1 ${loading ? 'animate-spin' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>
          </button>
        </div>
        
        {error && (
          <div className="bg-red-100 text-red-700 p-4 rounded-md mb-4">
            {error}
          </div>
        )}
        
        {loading && (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
          </div>
        )}
        
        {!loading && filteredTechnicians.length === 0 && (
          <div className="bg-yellow-50 text-yellow-700 p-4 rounded-md">
            No se encontraron técnicos que coincidan con los criterios de búsqueda.
          </div>
        )}
        
        {!loading && filteredTechnicians.length > 0 && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {filteredTechnicians.slice(0, 20).map((tech) => (
              <div key={tech.id} className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200">
                <div className="p-6">
                  <div className="flex items-start space-x-4">
                    {/* Technician profile image */}
                    <div className="flex-shrink-0">
                      <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-200 flex items-center justify-center">
                        {tech.profile_image ? (
                          <img 
                            src={tech.profile_image ? `/uploads/${tech.profile_image}` : '/default-avatar.png'} 
                            alt={tech.name || 'Technician'}
                            className="w-full h-full object-cover"
                            onError={(e) => {
                              console.log(`Error loading image for ${tech.name}: ${tech.profile_image}`);
                              e.target.onerror = null; 
                              e.target.src = 'https://via.placeholder.com/64?text=T';
                            }}
                          />
                        ) : (
                          <span className="text-2xl text-gray-400">{tech.name ? tech.name.charAt(0).toUpperCase() : 'T'}</span>
                        )}
                      </div>
                    </div>
                    
                    {/* Technician info */}
                    <div className="flex-1">
                      <div className="flex justify-between">
                        <h3 className="text-lg font-semibold text-gray-800">{tech.name}</h3>
                        <div className="flex items-center text-amber-500">
                          <Star className="h-5 w-5 fill-current" />
                          <span className="ml-1">{tech.average_rating && typeof tech.average_rating === 'number' ? tech.average_rating.toFixed(1) : '0.0'}</span>
                        </div>
                      </div>
                      <p className="text-sm text-blue-600 mt-1">{tech.category_name || 'Sin categoría'}</p>
                      <div className="flex items-center text-gray-600 mt-2">
                        <MapPin className="h-4 w-4" />
                        <span className="ml-1 text-sm">{tech.city || 'Santiago'}</span>
                      </div>
                    </div>
                  </div>
                  
                  <div className="mt-4">
                    <div className="flex justify-between items-center">
                      <span className="text-sm text-gray-600">{tech.review_count || 0} reseñas</span>
                      <span className="text-lg font-bold text-gray-800">RD${tech.hourly_rate || '0'}</span>
                    </div>
                  </div>
                  <div className="mt-4">
                    <button
                      onClick={() => navigate(`/technicians/${tech.id}`)}
                      className="block w-full py-2 bg-blue-600 hover:bg-blue-700 text-white text-center rounded-md"
                    >
                      Ver Perfil
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        
        {filteredTechnicians.length > 20 && (
          <div className="text-center mt-8">
            <button
              onClick={handleSearch}
              className="px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md"
            >
              Ver Más Técnicos
            </button>
          </div>
        )}
      </div>
      
      {/* How it Works Section */}
      <div className="bg-gray-100 py-16 px-4">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold text-center mb-8">Cómo Funciona</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md text-center">
              <div className="bg-blue-100 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <Search className="h-8 w-8 text-blue-600" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Busca</h3>
              <p className="text-gray-600">Encuentra el técnico que necesitas según la especialidad y ubicación.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md text-center">
              <div className="bg-blue-100 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <User className="h-8 w-8 text-blue-600" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Contacta</h3>
              <p className="text-gray-600">Comunícate directamente con los técnicos que mejor se adapten a tus necesidades.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md text-center">
              <div className="bg-blue-100 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <Star className="h-8 w-8 text-blue-600" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Califica</h3>
              <p className="text-gray-600">Comparte tu experiencia y ayuda a otros a encontrar los mejores técnicos.</p>
            </div>
          </div>
          <div className="text-center mt-8">
            <button
              onClick={() => navigate('/como-funciona')}
              className="px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md"
            >
              Más Información
            </button>
          </div>
        </div>
      </div>
      
      {/* Footer */}
      <footer className="bg-gray-800 text-gray-300 py-8 px-4">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h4 className="text-white text-lg font-semibold mb-4">TechConnect Santiago</h4>
              <p className="mb-2">La mejor plataforma para conectar con técnicos profesionales en Santiago.</p>
            </div>
            <div>
              <h4 className="text-white text-lg font-semibold mb-4">Enlaces</h4>
              <ul className="space-y-2">
                <li><button onClick={() => navigate('/')} className="hover:text-white">Inicio</button></li>
                <li><button onClick={() => navigate('/como-funciona')} className="hover:text-white">Cómo Funciona</button></li>
                <li><button onClick={() => navigate('/register')} className="hover:text-white">Registrarse</button></li>
                <li><button onClick={() => navigate('/register-technician')} className="hover:text-white">Ser Técnico</button></li>
              </ul>
            </div>
            <div>
              <h4 className="text-white text-lg font-semibold mb-4">Contacto</h4>
              <ul className="space-y-2">
                <li>info@techconnect.do</li>
                <li>+1 (829) 123-4567</li>
                <li>Santiago de los Caballeros, RD</li>
              </ul>
            </div>
            <div>
              <h4 className="text-white text-lg font-semibold mb-4">Síguenos</h4>
              <div className="flex space-x-4">
                <button className="hover:text-white">Facebook</button>
                <button className="hover:text-white">Instagram</button>
                <button className="hover:text-white">Twitter</button>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-700 mt-8 pt-4 text-center">
            <p>&copy; {new Date().getFullYear()} TechConnect Santiago. Todos los derechos reservados.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Landing; 
